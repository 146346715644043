import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementTw from 'element-ui/lib/locale/lang/zh-TW'
import elementJa from 'element-ui/lib/locale/lang/ja'// 日语
import elementFr from 'element-ui/lib/locale/lang/fr.js' // 法语
import elementDe from 'element-ui/lib/locale/lang/de.js' // 德语
import elementIt from 'element-ui/lib/locale/lang/it.js' // 意大利语
import elementEs from 'element-ui/lib/locale/lang/es.js' // 西班牙语
import elementRu from 'element-ui/lib/locale/lang/ru-RU.js' // 俄罗斯语
import elementPl from 'element-ui/lib/locale/lang/pl.js' // 波兰语
import elementZh from 'element-ui/lib/locale/lang/zh-CN.js' // 波兰语
import elementMs from 'element-ui/lib/locale/lang/ms.js' // 波兰语

// 引入自定义中文包
 import customTw from './tw'    
// 引入自定义英文包
 import customEN from './en' 
//  日语
import customJa from './ja'
// 法语
import customFr from './fr'
// 德语
import customDe from './de'
// 意大利
import customIt from './it'
// 西班牙
import customEs from './es'
// 俄罗斯
import customRu from './ru'
// 波兰
import customPl from './pl'
// 中文
import customZh from './zh'
// 马来
import customMs from './ms'

Vue.use(VueI18n)
console.log(location.href,'h狼f-------')
if(!localStorage.lang && !localStorage.visit) {
  let href = location.href
  // 新加坡/test
  if(href.indexOf('/cooknjoy') !== -1 || href.indexOf('/c2-') !== -1) {
      let lang = navigator.languages? navigator.languages[0] : (navigator.language || navigator.userLanguage)
      
      if(lang.indexOf('zh-TW') !== -1) {
          localStorage.lang = 'tw'
          localStorage.langT='zh_TW'
      } else if(lang.indexOf('ja') !== -1){
          localStorage.lang = 'ja'
          localStorage.langT='ja_JP'
      } else{
        localStorage.lang = 'en'
        localStorage.langT='en_US'
      }
  } else if(href.indexOf('/ru') !== -1){
    console.log('二层二层----')
     localStorage.lang = 'ru'
     localStorage.langT='ru_RU'
  }else{
    console.log('else----')
      // 欧洲,北美,英国,新加坡使用英语
      localStorage.lang = 'en'
      localStorage.langT='en_US'
  }
  
  localStorage.visit = 1
} 

 
// 创建国际化插件的实例
export default new VueI18n({
  // 指定语言类型
  locale: localStorage.lang || 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      ...elementEN, // 将饿了么的英文语言包引入
      ...customEN   // 将自定义英文包加入
    },
    tw: {
      ...elementTw, 
      ...customTw   
    },
    ja: {
      ...elementJa,
      ...customJa
    },
    fr: {
      ...elementFr,
      ...customFr
    },
    de: {
      ...elementDe,
      ...customDe
    },
    it: {
      ...elementIt,
      ...customIt
    },
    es: {
      ...elementEs,
      ...customEs
    },
    ru:{
      ...elementRu,
      ...customRu
    },
    pl:{
      ...elementPl,
      ...customPl
    },
    ms:{
      ...elementMs,
      ...customMs
    },
    zh:{
      ...elementZh,
      ...customZh
    }
  }
})