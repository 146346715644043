/**
 * 俄罗斯
 */
 export default{
    header:{
        Popular:'Популярный',
        collections:'Последний сборник рецептов',
        store:'Магазин',
        about:'О TOKIT',
        account:'Моя учетная запись',
        collection:'Моя коллекция',
        creation:'Мои творения',
        device:'Мое оборудование',
        signIn:'Логин',
        loginOut:'Удалить'
    },
    fotter:{
        privacy:'Политика конфиденциальности частной информации',
        terms:'Условия предоставления услуг',
        reserved:'Все права защищены：TOKIT 2020 tokit.com'
    },
    home:{
        featuredrecipes:'Меню',
        more:'Узнать побольше',
        collections:'Популярный сборник рецептов',
        delicacy:'Деликатес со своими особенностями',
        collection:'Ежедневная коллекция',
        latestRecipes:'Последние кулинарные рецепты',
        cookbook:'Поиск',
        search:'Поиск',
        link:'Быстрое соединение',
        getTheDelicacies: 'Добыть деликатес',
        gainSomeInspirations: "Получить немного вдохновения"
    },
    recipesearch:{
        noresult:'Результаты выбранного поиска не найдены.',
        viewMore:'Посмотреть больше',
        collection:'Сборник  рецептов был успешно обновлен!',
        recipe:'Рецепт был удален из подборки!',
        noMore: 'Больше данных пока нет!',
        newest: 'Новейший',
        shortest: 'Самый популярный',
        recipes: 'Рецепты',
        collections: 'Коллекции',
        tags: 'Теги',   
        products: 'Продукт',
        category: 'Категория',
        showResults: 'Показать результаты',
        Filters: 'Фильтры',
        showAll: 'Показать все',
        hideAll: 'Крах',
    },
    specialTopicList:{
        explore:'Искать наши последние серии рецептов',
        viewAll: 'Просмотреть все',
        Recipes:'Рецепт каких блюд'
    },
    login:{
        logs:'Войти в вашу учетную запись',
        email:'Адрес электронной почты',
        psd:'Пароль',
        by:'Через вход в систему, вы соглашаетесь:',
        useragreement:'Пользовательское соглашение',
        and:'И',
        login:'Логин',
        or:'Или',
        createaccount:'Создать учетную запись',
        forgotpassword:'Забыть пароль',
        reserved:'© 2021, Chunmi Technology (Hong Kong) Limited Все права защищены.',
        agree:'Я согласен(а)',
        privacy:'Пожалуйста, прочитайте и согласитесь с нашей политикой.',
        enterpass:'Ввести адрес  вашей электронной почты, чтобы получить толкование о том, как сбросить пароль.',
        exist:'Эта учетная запись не существует.',
        later:'Что-то пошло не так, пожалуйста, попробуйте еще раз позже.',
        reset:'Сброс',
        return:'Или вернуться к',
        denglu:'Логин',
        resetpsd:'Сбросить пароль',
        should:'Вы должны получить ссылку в течение нескольких минут. Пожалуйста, откройте эту ссылку, чтобы сбросить свой пароль.',
        backIn:'Вернуться к входу в систему',
        setpad:'Установить пароль',
        minimum:'Не менее 8 символов, по крайней мере, одна заглавная буква, одна строчная буква и одна цифра',
        confirm:'Подтверждать',
        confirmpsd:'Подтвердить пароль',
        passwordMismatch:'Пароль не совпадает дважды',
        incorrect :'Неверный формат пароля',
        valids:'Пожалуйста, предоставьте действительный пароль.',
        setName:'Установите ваше имя пользователя',
        tip:'Никнейм должен быть уникальным, использовать китайский и английский языки и не содержать специальных символов',
        wrong:'Что-то пошло не так, пожалуйста, попробуйте еще раз позже.',
        usName:'Имя пользователя было использовано.',
        save:'Сохранить',
        validmail:'Пожалуйста, предоставьте действительный адрес электронной почты.',
        validpassword:'Пожалуйста, предоставьте действительный пароль.',
        validname:'Пожалуйста, предоставьте действительное название',
        loginReminder:'Напоминание о входе в систему',
        format:'Пожалуйста, совпадите с запрошенным форматом!'
    },
    recipeDetails:{
        difficulty:'Степень трудности',
        serves:'Услуга',
        preparationtime:'Время подготовки',
        totaltime:'Общее время',
        ingredients:'Ингредиент',
        preparation:'Подготовка',
        relatedrecipes:'Соответствующие рецепты',
        min:'Балл',
        reterecipe:'Оценить этот рецепт',
        evaluationTips:'От 1 звездочки (очень плохо) до 5 звездочек (очень хорошо), как вы оцениваете этот рецепт?',
        angry:'Сердиться',
        disappointed:'Разочароваться',
        average:'Так себе',
        satisfied:'Удовлетворить',
        vsatisfied:'Очень удовлетворить',
        clear:'Отменять',
        saveRecipes: 'Сохранить рецепт',
        removeRecipes: 'Удалить рецепт',
        supportedProducts: 'Совместимые продукты',
        tags: 'Теги',
        tips:'Cоветы',
        edit: 'Редактировать',
        Duration:'Продолжительность',
        Temperature:'Температура',
        Speed:'Скорость',
        corotation:'',
        reversal:'-',
        minutes:'минут',
        easy:'Легкий',
        Medium:'Середина',
        Difficult:'Трудный',
        title:'Детали рецепта',
        positive:'По часовой стрелке',
        reverse:'Против часовой стрелки',
        needHour:'часов',
        needMin:'минут',
    },
    account:{
        hi:'Здравствуй,',
        profile:'Мои личные данные',
        collection:'Моя коллекция',
        creation:'Мои творения',
        device:'Мое оборудование',
        editprofile:'Редактировать файл конфигурации',
        firstname:'Имя',
        lastname:'Фамилия',
        gender:'Пол',
        birthday:'Дата рождения',
        country:'Страна/регион',
        email:'Электронная почта',
        phonenum:'Номер телефона',
        bundle:'Привязать ваше оборудование TOKIT и наслаждаться лучшим сервисом',
        addvice:'Добавить оборудование',
        bingdevice:'Переплетное оборудование',
        product:'Продукт',
        blank:'Продукт не может быть пустым',
        deviceNo:'Номер оборудования',
        modelBlank:'Номер модели не может быть пустым',
        submit:'Представить',
        guide:'Руководство по просмотру номера оборудования',
        content:'Откройте меню содержимого, одинарно щелкните Настройки, чтобы перейти на страницу, затем одинарно щелкните на то перейти на эту страницу для поиска оборудования, модели которого не показаны. Узнайте больше о',
        fullTxt:'Полный процесс привязки',
        adddevice:'Добавить мое оборудование',
        choose:'Пожалуйста, выберите ваш продукт.',
        modelNum:'Модель',
        personal:'Личная информация',
        male:'Мужской',
        female:'Женский',
        country:'Страна или регион',
        year:'Год',
        month:'Месяц',
        day:'Число',
        complete:'Пожалуйста, завершите привязку на вашем оборудовании.',
        completed:'Завершение',
        noDevice:'На моем оборудовании нет всплывающего окна',
        tryAlign:'Пробовать еще раз',
        removeCollection:'Удалить сборник рецептов',
        doreally:'Вы действительно хотите удалить сборник  рецептов?',
        firstRecipe:'Сохраните ваш первый рецепт!',
        theSave:'Нажмите сохранить',
        icon:'Что касается значка на рецепте, сохранить его для использования в будущем.',
        findRecipe:'Искать рецепты',
        successfully:'Привязка прошла успешно!',
        removedevice:'Оборудование было успешно удалено.',
        bindingFailed:'Не удалась привязка',
        removeTOKIT: 'Удалять TOKIT',
        removeTips: 'Вы действительно хотите деактивировать свой TOKIT?',
        cancel: 'Oтмена',
        remove: 'Удалять',
		loadTip:'Загрузите приложение для привязки устройств'
    },
    aboutus:{
        seek:'Что мы ищем',
        helping:'Помогает облегчить процесс приготовления пищи',
        journey:'Приготовление пищи теперь может стать легким и приятным путешествием.',
        providing:'Предоставить интеллектуальные решения для приготовления пищи',
        continuing:'Продолжать исследовать и разрабатывать самые умные кухонные опыты.',
        database:'База данных по приготовлению пищи на основе облачных платформ',
        building:'Создать более расширенное и фантастическое выборное меню для пользователей.',
        history:'История',
        previous:'Предыдущий',
        next:'Следующий',
        ourJourney:'Наше путешествие началось в мае',
        established:"Было основано в мае 2013 года, TOKIT Technology запустила spark и начала работать над разработкой и применением Интернета вещей и бытовой техники, объединяющейся с облачной платформой.",
        ourfirst:'Первый продукт, который мы запустили',
        intend:'Чтобы переопределить кухонную утварь в соответствии с научно-техническими требованиями электронных продуктов, мы начали модернизацию и реформирование начиная с рисоварок.',
        awards:'Награды, которые мы завоевали с 2020 года',
        since:'С момента своего образования мы были признаны многими организациями и получили 48 наград от IF, Red Dot, G-Mark, IDEA и других учреждений.',
        launched:'Запуск прототипа TOKIT',
        developing:'В течение 2020 года мы разрабатывали прототипы TOKIT, обозначенные как T0, T1 и T2.',
        Kickstarter:'$1142227 на летнем Kickstartor',
        reached:'С официальным запуском нашего TOKIT Omni Cook по всему миру на kickerstarter появилось 1566 подписчиков, а доход от реализации достиг 1 142 227 долларов США',
        website:'Грядущий сентябрь website And Cooknjoy',
        distribution:'Мы запустили глобальный официальный веб-сайт и платформу Cooknjoy в сентябре 2021 года, чтобы подготовиться к глобальной дистрибуции TOKIT.',
        Soon:'Скоро появится joyami',
        cooker:'Мы запустим наш суббренд TOKIT joyami в конце 2021 года. Ниже приведен предварительный просмотр, предоставленный joyami для нашей новой рисоварки.',
        define:'Отношение к приготовлению пищи зависит от температуры рисоварки',
        creating:'Надежная технология создать насыщенную жизнь',
        staffed:'В его состав входят 300 представителей элиты мировых технологических гигантов Apple, IBM, Philips и многих других компаний; на долю Team D приходится 65%. На данный момент у нас есть более 800 патентов, и мы оснащены интернет-генетикой и передовыми производственными технологиями'
    },
    creation:{
        edit:'Изменить рецепт',
        delete:'Удалить рецепт',
        delTitle:'Удаление рецепта',
        delDesc:'Вы уверены, что хотите удалить этот рецепт?',
        btnCancle:'Отмена',
        btnDel:'Удалить',
        delSuccess:'Рецепт удален!',
        delFailed:'Удаление не удалось, попробуйте еще раз позже.',
        addRecipe:'НАЧНИТЕ ДОБАВЛЯТЬ СВОИ РЕЦЕПТЫ',
        addDesc:'Создавать и редактировать рецепты так просто, приходите и попробуйте!',
        publishRecipe:'Загрузить рецепт',
        reviewing:'На рассмотрении',
        reviewFailed:'Не прошел проверку',
    },
    userRecipe: {
        createdBy: "Создано",
        done: "СДЕЛАННЫЙ",
        uploadCoverImg: "Загрузить изображение обложки",
        changeCoverImg: "Изменить изображение обложки",
        browser: "Просматривать",
        uploadTips: "Поддерживаемые форматы изображений: jpg, jpeg, png, bmp, webp; до 2 МБ; рекомендуемый размер: ",
        uploadLimitTip: "Размер изображения превышает лимит в 2 МБ. Пожалуйста, выберите еще раз.",
        confirm1: "Подтверждать",
        reselect: "Повторный выбор",
        uploadFail: "Не удалось загрузить изображение обложки. Повторите попытку позже.",
        recipeName: "Название рецепта",
        inputRecipeName: 'Пожалуйста, введите название рецепта',
        recipeNameTips: 'В настоящее время имя не поддерживает специальные символы.',
        description: "Описание рецепта",
        inputDesc: 'Введите описание (необязательно)',
        moreInfo: "Дополнительная информация",
        supportProduct: "Совместимые продукты",
        difficulty: "Сложность",
        prepareTime: "Время подготовки",
        totalTime: "Общее время",
        portion: "Служит",
        productTips: "В настоящее время поддерживается только создание рецептов для этого продукта. Дополнительные функции создания продуктов появятся в сети...",
        easy: "Легкий",
        medium: "Середина",
        difficult: "Трудный",
        hour: "часы",
        minute: "минут",
        cancel: "Отмена",
        confirm: "Конечно",
        setTimeLimit24: "Установите ограничение продолжительности на 24 часа.",
        peopleLimit20: "Установите верхний предел в 20 человек/порций",
        selectDifficult: "Пожалуйста, выберите сложность приготовления",
        setPrepareTime: "Пожалуйста, выберите время приготовления",
        setTotalTime: "Пожалуйста, выберите общее время",
        setPeopleNum: "Пожалуйста, выберите количество человек, которые будут обедать",
        ingredients: "Пожалуйста, добавьте хотя бы 1 ингредиент.",
        addIngredients: "ДОБАВИТЬ ИНГРЕДИЕНТЫ",
        ingredientLimitTips: "Вы можете добавить до 20 видов ингредиентов.",
        steps: "Шаги",
        addSteps: "ДОБАВИТЬ ШАГИ",
        stepLimitTips: "Вы можете добавить до 15 шагов.",
        uploadStepImg: "Загрузить изображение шага",
        changeStepImg: "Изменить изображение шага",
        inputStepDesc: "Введите описание шага",
        stepParams: "Параметры шага",
        second: "секунды",
        timeLimitTips: "Установите максимальную продолжительность 99 минут.",
        timesAlreadyEdit: 'Длительность была скорректирована до допустимой для вас комбинации.',
        inputTemp: "Температура",
        tempTips: "Диапазон температур составляет 35-180℃, и может регулироваться только с шагом в 5 градусов.",
        tempOff: "Температура выключена",
        tempOn: "Температура на",
        tempAlreadyEdit: "Температура была отрегулирована до эффективной комбинации для вас.",
        selectSpeed: "Пожалуйста, выберите скорость",
        positiveSpeed: "По часовой стрелке",
        reverseSpeed: "Против часовой стрелки",
        tips: "Советы",
        inputTips: "Введите чаевые (необязательно)",
        label: "Теги",
        addLabel: "ДОБАВИТЬ ТЕГИ",
        viewMore: "Посмотреть больше",
        coverImgerror: "Пожалуйста, добавьте изображение обложки рецепта",
        recipeNameError: "Пожалуйста, введите название рецепта",
        ingredientError: "Пожалуйста, добавьте хотя бы 1 ингредиент.",
        stepError: "Добавьте хотя бы 1 шаг.",
        labelError: "Пожалуйста, добавьте хотя бы 1 категорию.",
        ingredientNameError: "Название ингредиента не может быть пустым.",
        stepDescError: "Описание шага не может быть пустым.",
        ingredientName: "вода",
        ingredientUnit: "100г",
        simpleHour: 'ч',
        simpleMin: 'мин'
    }
}