/**
 * 繁体中文
 */
export default{
    header:{
        Popular:'受欢迎的',
        collections:'最新的合集',
        store:'商店',
        about:'关于TOKIT',
        account:'我的账户',
        collection:'我的收藏',
        creation:'我的创作',
        device:'我的设备',
        signIn:'登录',
        loginOut:'退出登录'
    },
    fotter:{
        privacy:'隐私政策',
        terms:'用户协议',
        reserved:'版权所有：TOKIT 2020 tokit.com'
    },
    home:{
        featuredrecipes:'特色食谱',
        more:'了解更多',
        collections:'受欢迎的合集',
        delicacy:'特色合集',
        collection:'每日收藏',
        latestRecipes:'最新食谱',
        cookbook:'搜索',
        search:'搜索',
        link:'快捷链接',
        getTheDelicacies: '获取美食',
        gainSomeInspirations: "获得一些灵感"
    },
    recipesearch:{
        noresult:'未找到所选搜索的结果。',
        viewMore:'查看更多',
        collection:'食谱已添加至收藏！',
        recipe:'食谱已从收藏中删除！',
        noMore: '暂无更多数据！',
        newest: '最新发布',
        shortest: '最受欢迎',
        recipes: '食谱',
        collections: '合集',
        tags: '标签',  
        products: '分类',
        category: '分类',
        showResults: '查看结果',
        Filters: '筛选', 
        showAll: '展示全部',
        hideAll: '收起',
    },
    specialTopicList:{
        explore:'探索我们的最新合集',
        viewAll: '查看全部',
        Recipes:'道食谱'
    },
    login:{
        logs:'登录您的账户',
        email:'电子邮件地址',
        psd:'密码',
        by:'通过登入，您同意：',
        useragreement:'用户协议',
        and:'和',
        login:'登录',
        or:'或',
        createaccount:'创建账户',
        forgotpassword:'忘记密码',
        reserved:'©2021，纯米科技（上海）股份有限公司。版权所有。',
        agree:'我同意',
        privacy:'请阅读并同意我们的政策。',
        enterpass:'输入您的电子邮件以接受有关如何重置密码的说明。',
        exist:'该账户不存在。',
        later:'出了点问题，请稍后再试。',
        reset:'重置',
        return:'或返回到',
        denglu:'登录',
        resetpsd:'重置密码',
        should:'您应该会在几分钟内收到一个链接。请打开该链接以重置密码。',
        backIn:'返回登录',
        setpad:'设置密码',
        minimum:'最少8个字符，至少一个大写字母、一个小写字母和一个数字',
        confirm:'确认',
        confirmpsd:'确认密码',
        passwordMismatch:'两次密码不匹配',
        incorrect :'密码格式不正确',
        valids:'请提供有效密码。',
        setName:'设置您的用户名',
        tip:'昵称需要唯一，使用中英文，且不包含特殊字符',
        wrong:'出了点问题，请稍后再试。',
        usName:'用戶名已被使用。',
        save:'保存',
        validmail:'请提供有效的电子邮件地址。',
        validpassword:'请提供有效密码。',
        validname:'请提供有效的名称',
        loginReminder:'登录提醒',
        format:'请匹配请求的格式'
    },
    recipeDetails:{
        difficulty:'难度',
        serves:'人/份',
        preparationtime:'准备时间',
        totaltime:'总时间',
        ingredients:'食材',
        preparation:'步骤',
        relatedrecipes:'相关食谱',
        min:'分',
        reterecipe:'评估这个食谱',
        evaluationTips:'从1星（非常差）到5星（非常好），你如何评估这个食谱？',
        angry:'生气',
        disappointed:'失望',
        average:'一般',
        satisfied:'满意',
        vsatisfied:'非常满意',
        clear:'取消',
        saveRecipes: '收藏食谱',
        removeRecipes: '取消收藏',
        supportedProducts: '适用产品',
        tags: '标签',
        tips:'小贴士',
        edit: '编辑',
        Duration:'时长',
        Temperature:'温度',
        Speed:'转速',
        corotation:'正转',
        reversal:'反转',
        minutes:'分钟',
        easy:'简单',
        Medium:'中等',
        Difficult:'较难',
        title:'食谱详情',
        positive:'正转',
        reverse:'反转',
        needHour:'小时',
        needMin:'分钟',
        
    },
    account:{
        hi:'你好，',
        profile:'我的个人资料',
        collection:'我的收藏',
        creation:'我的创作',
        device:'我的设备',
        editprofile:'编辑个人资料',
        firstname:'名字',
        lastname:'姓氏',
        gender:'性别',
        birthday:'生日',
        country:'国家/地区',
        email:'email',
        phonenum:'电话号码',
        bundle:'捆绑您的TOKIT设备，享受更好的服务',
        addvice:'添加设备',
        bingdevice:'绑定设备',
        product:'产品',
        blank:'产品不能为空',
        deviceNo:'设备编号',
        modelBlank:'型号不能为空',
        submit:'提交',
        guide:'设备编号查看指南',
        content:'打开内容选单，按一下设定跳转到页面，然后按一下“关于”跳转到该页面，以查找型号未显示的设备。 了解更多关于',
        fullTxt:'完整绑定过程',
        adddevice:'添加我的设备',
        choose:'请选择您的产品。 ',
        modelNum:'型号',
        personal:'个人资料',
        male:'男',
        female:'女',
        country:'国家或地区',
        year:'年份',
        month:'月份',
        day:'天',
        complete:'请在您的设备上完成绑定。 ',
        completed:'完成',
        noDevice:'我的设备上没有快显视窗',
        tryAlign:'再试一次',
        removeCollection:'删除集合',
        doreally:'是否确实要删除此集合？ ',
        firstRecipe:'保存您的第一个食谱！ ',
        theSave:'点击保存',
        icon:'在配方上的图标，以将其保存以备将来使用。 ',
        findRecipe:'查找食谱',
        successfully:'绑定成功！ ',
        removedevice:'设备已成功删除。 ',
        bindingFailed:'绑定失败',
        removeTOKIT: '删除TOKIT',
        removeTips: '你真的想停用你的TOKIT吗？ ',
        cancel: '取消',
        remove: '删除',
        loadTip:'下载APP绑定设备'
    },
        aboutus:{
        seek:'我们寻求什么',
        helping:'帮助烹饪更容易',
        journey:'烹饪现在可以是一次轻松愉快的旅程。 ',
        providing:'提供智慧烹饪解决方案',
        continuing:'继续研究和开发最智慧的厨房体验。 ',
        database:'基于云的烹饪资料库',
        building:'为用户构建一个更加扩展和富有想像力的选单。 ',
        history:'历史',
        previous:'上一个',
        next:'下一个',
        ourJourney:'我们的旅程始于五月',
        established:"成立于2013年5月，TOKIT Technology启动了spark，并开始致力于物联网和云家电的开发和应用。",
        ourfirst:'我们推出的第一个产品',
        intend:'为了根据电子产品的科技要求重新定义厨房用具，我们从电锅开始进行升级和改革。 ',
        awards:'我们自2020年以来获得的奖项',
        since:'自成立以来，我们获得了许多组织的认可，从IF、Red Dot、G-Mark、IDEA等机构获得了48个奖项。 ',
        launched:'TOKIT 原型发布',
        developing:'我们在整个2020年开发了TOKIT原型，列为T0、T1和T2',
        Kickstarter:'$1142227在夏季Kickstartor上',
        reached:'随着我们的TOKIT Omni Cook在全球正式推出，kickerstarter上有1566名支持者，销售额达到1142227美元',
        website:'website And Cooknjoy即将到来的九月',
        distribution:'我们于2021 9月推出了全球官方网站和Cooknjoy平台，为TOKIT全球分销做准备。 ',
        Soon:'joyami很快就要来了',
        cooker:'我们将在2021年底推出我们的TOKIT子品牌joyami。下面是joyami为我们的新电锅提供的预览。 ',
        define:'用温度定义烹饪态度',
        creating:'创造丰富生活的可靠科技',
        staffed:'由来自全球科技巨头苹果、IBM、飞利浦和许多其他公司的300名精英组成； D队占65%。 到目前为止，我们拥有800多项专利，并配备了互联网遗传学和先进制造技术'
    },
    creation:{
        edit:'修改食谱',
        delete:'删除食谱',
        delTitle:'删除食谱',
        delDesc:'是否确定要删除这道食谱？',
        btnCancle:'取消',
        btnDel:'删除',
        delSuccess:'食谱已删除！',
        delFailed:'删除失败，请稍后再试。',
        addRecipe:'开始添加你的食谱',
        addDesc:'创作和编辑食谱如此简单，快来试试吧！',
        publishRecipe:'上传食谱',
        reviewing:'审核中',
        reviewFailed:'审核不通过',
    },
    userRecipe: {
        createdBy: "作者",
        done: "完成",
        uploadCoverImg: "上传封面图",
        changeCoverImg: "更换封面图",
        browser: "浏览",
        uploadTips: "图片格式支持jpg、jpeg、png、bmp、webp；2MB以内；建议尺寸 ",
        uploadLimitTip: "超出图片大小限制2MB，请重新选择",
        confirm1: "确认",
        reselect: "重选",
        uploadFail: "封面图上传失败，请稍后重试。",
        recipeName: "食谱名称",
        inputRecipeName: '请填写食谱名称',
        recipeNameTips: '名称暂不支持特殊字符',
        description: "食谱描述",
        inputDesc: '请输入描述（选填）',
        moreInfo: "更多信息",
        supportProduct: "适用产品",
        difficulty: "难度",
        prepareTime: "准备时间",
        totalTime: "总时间",
        portion: "人/份",
        productTips: "当前仅支持该产品的食谱创作，更多产品创作功能陆续上线中...",
        easy: "简单",
        medium: "中等",
        difficult: "较难",
        hour: "小时",
        minute: "分钟",
        cancel: "取消",
        confirm: "确定",
        setTimeLimit24: "设置时长上限24小时",
        peopleLimit20: "设置人/份上限20",
        selectDifficult: "请选择烹饪难度",
        setPrepareTime: "请选择准备时间",
        setTotalTime: "请选择总时间",
        setPeopleNum: "请选择用餐人数",
        ingredients: "食材",
        addIngredients: "添加食材",
        ingredientLimitTips: "食材最多添加20种哦",
        steps: "步骤",
        addSteps: "添加步骤",
        stepLimitTips: "步骤最多添加15个哦",
        uploadStepImg: "上传步骤图",
        changeStepImg: "更换步骤图",
        inputStepDesc: "请输入步骤描述",
        stepParams: "步骤参数",
        second: "秒",
        timeLimitTips: "设置时长上限99分钟",
        timesAlreadyEdit: '时长已为您调整至有效组合',
        inputTemp: "请填写温度",
        tempTips: "温度范围为35-180℃，仅支持按5度调整",
        tempOff: "温度 不加热",
        tempOn: "温度 加热",
        tempAlreadyEdit: "温度已为您调整至有效组合",
        selectSpeed: "请选择转速",
        positiveSpeed: "正转",
        reverseSpeed: "反转",
        tips: "小贴士",
        inputTips: "请输入小贴士（选填）",
        label: "标签",
        addLabel: "添加标签",
        viewMore: "查看更多",
        coverImgerror: "请添加食谱封面图",
        recipeNameError: "请填写食谱名称",
        ingredientError: "请至少添加1种食材",
        stepError: "请至少添加1个步骤",
        labelError: "请至少添加1个分类",
        ingredientNameError: "食材名称不能为空",
        stepDescError: "步骤描述不能为空",
        ingredientName: "水",
        ingredientUnit: "100g",
        simpleHour: '小时',
        simpleMin: '分钟'
    }
}