/**
 * 英语
 */
export default{
    header:{
        Popular:'Popular',
        collections:'Latest Collections',
        store:'Store',
        about:'About TOKIT',
        account:'My account',
        collection:'My collection',
        creation:'My creation',
        device:'My device',
        signIn:'Sign in',
        loginOut:'Log Out'
    },
    fotter:{
        privacy:'Privacy policy',
        terms:'Terms of service',
        reserved:'TOKIT 2020 tokit.com All rights reserved.'
    },
    home:{
        featuredrecipes:'Featured Recipes',
        more:'Learn More',
        collections:'Popular Collections',
        delicacy:'Featured Delicacy',
        collection:'Collection of the Day',
        latestRecipes:'Latest Recipes',
        cookbook:'Search',
        search:'Search',
        link:'Quick Links',
        getTheDelicacies: 'Get the Delicacies',
        gainSomeInspirations: "Gain some Inspirations"
    },
    recipesearch:{
        noresult:'No results were found for the selected search.',
        viewMore:'View more',
        collection:'Collection was successfully updated!',
        recipe:'Recipe was remove from collection!',
        noMore: 'No more data!',
        newest: 'Newest',
        shortest: 'Most Popular',
        recipes: 'Recipes',
        collections: 'Collections',
        tags: 'Tags',   
        products: 'Product',
        category: 'Category',
        showResults: 'Show results',
        Filters: 'Filters', 
        showAll: 'Show all',
        hideAll: 'Collapse',
    },
    specialTopicList:{
        explore:'Explore our Latest Collections',
        viewAll: 'View All',
        Recipes:'Recipes'
    },
    login:{
        logs:'Log in to Your Account',
        email:'Email address',
        psd:'Password',
        by:'By logging in, you agree to ',
        useragreement:'User Agreement',
        and:'and',
        login:'Login',
        or:'Or',
        createaccount:'Create Account',
        forgotpassword:'Forgot password',
        reserved:'© 2021, Chunmi Technology (Hong Kong)Limited. All rights reserved.',
        agree:'I agree to',
        privacy:'Please read and agree to our policy.',
        enterpass:'Enter your email to receive instructions on how to reset your password.',
        exist:'The account does not exist.',
        later:'Somthing went wrong, please try later.',
        reset:'Reset',
        return:'Or return to',
        denglu:'Log in',
        resetpsd:'Reset Password',
        should:' You should receive a link in a few moments. Please open that link to reset your password.',
        backIn:'Back to log in',
        setpad:'Set Your Password',
        minimum:'Minimum 8 characters, at least one uppercase letter, one lowercase letter and one number',
        confirm:'Confirm',
        confirmpsd:'Confirm Password',
        passwordMismatch:'Password mismatch',
        incorrect :'Incorrect password format',
        valids:'Please provide a valid password.',
        setName:'Set your Username',
        tip:'Nicknames should be unique, in Chinese and English, and do not contain special characters',
        wrong:'Somthing went wrong, please try later.',
        usName:'The username has already been taken.',
        save:'Save',
        validmail:'Please provide a valid email address.',
        validpassword:'Please provide a valid password.',
        validname:'Please provide a valid name',
        format:'Please match the requested format',
        loginReminder:'Login reminder',
    },
    recipeDetails:{
        difficulty:'Difficulty',
        serves:'Serves',
        preparationtime:'Preparation time',
        totaltime:'Total time',
        ingredients:'Ingredients',
        preparation:'Preparation',
        relatedrecipes:'Related Recipes',
        min:'min',
        reterecipe:'Rate this recipe',
        evaluationTips:'On a scale of 1 star (very bad) to 5 stars (very good), how would you rate this recipe?',
        angry:'angry',
        disappointed:'disappointed',
        average:'average',
        satisfied:'satisfied',
        vsatisfied:'very satisfied',
        clear:'CANCEL',
        saveRecipes: 'Save Recipe',
        removeRecipes: 'Remove Recipe',
        supportedProducts: 'Compatible products',
        tags: 'Tags',
        tips:'Tips',
        edit: 'Edit',
        Duration:'Duration',
        Temperature:'Temperature',
        Speed:'Speed',
        corotation:'',
        reversal:'-',
        minutes:'minutes',
        easy:'Easy',
        Medium:'Medium',
        Difficult:'Difficult',
        title:'Recipe Details',
        positive:'Positive speed',
        reverse:'Reverse speed',
        needHour:'hours',
        needMin:'minutes',
    },
    account:{
        hi:'Hi,',
        profile:'My profile',
        collection:'My collection',
        creation:'My creation',
        device:'My device',
        editprofile:'Edit profile',
        firstname:'First Name',
        lastname:'Last Name',
        gender:'Gender',
        birthday:'Birthday Date',
        country:'Country/Region',
        email:'Email',
        phonenum:'Phone Number',
        bundle:'Bundle your TOKIT device and enjoy better service',
        addvice:'Add device',
        bingdevice:'Binding devices',
        product:'Product',
        blank:'Product cannot be blank',
        deviceNo:'Device NO',
        modelBlank:'Model Number cannot be blank.',
        submit:'SUBMIT',
        guide:'Device Number Viewing Guide',
        content:'Open the content menu, click Settings to jump to the page, and then jump to the page by clicking about to find the Device NO shown by the Model Numbe.Learn more about ',
        fullTxt:'Full Binding process',
        adddevice:'Add My Devices',
        choose:'Please choose your product.',
        modelNum:'Serial Number',
        personal:'Personal Information',
        male:'Male',
        female:'Female',
        country:'Country Or Region',
        year:'Year',
        month:'Month',
        day:'Day',
        removeCollection:'Remove collection',
        doreally:'Do you really want to remove this collection?',
        firstRecipe:'Save Your First Recipe!',
        theSave:'Tap the save',
        icon:'icon on a recipe to save it for later.',
        findRecipe:'FIND RECIPES',
        complete:'Please complete the binding on your device.',
        completed:'Completed',
        tryAlign:'try again',
        noDevice:'No pop-up on my device',
        successfully:'Binding successfully!',
        removedevice:'Device removed successfully.',
        bindingFailed:'Binding failed',
        removeTOKIT: 'Remove TOKIT',
        removeTips: 'Do you really want to deactivate your TOKIT?',
        cancel: 'CANCEL',
        remove: 'REMOVE',
		loadTip:'Download APP to bind devices'
    },
    aboutus:{
        seek:'What We Seek',
        helping:'Helping To Make Cooking Easier',
        journey:'Cooking can now be a relaxing enjoyable journey.',
        providing:'Providing Smart Cooking Solutions',
        continuing:'Continuing research and development for the smartest kitchen experience.',
        database:'Cloud Based Cooking Database',
        building:'Building a more extended and imaginative menu for users.',
        history:'History',
        previous:'Previous',
        next:'Next',
        ourJourney:'Our Journey Began in May',
        established:"Established in May 2013, TOKIT Technology began the spark and started dedicating it's development and application of IOT and Cloud household appliances.",
        ourfirst:'Our First Product Launched',
        intend:'Intending to redefine kitchen appliances to the technological requirements of electronic products, we initiated upgrading and reforms starting with our rice cookers. ',
        awards:'Awards We Won Since 2020',
        since:'Since our inception we received recognition from many organisation, collecting 48 awards from IF, Red Dot, G-Mark, IDEA and many more.',
        launched:'TOKIT Prototypes Launched',
        developing:'We were developing the TOKIT prototype listed as T0, T1 and T2 throughout the whole of 2020.',
        Kickstarter:'$1,142,227 On Kickstarter During Summer',
        reached:'We reached sales of $1,142,227 with 1566 backers on kickerstarter with the official global launch of our TOKIT Omni Cook.',
        website:'Website And Cooknjoy Coming September',
        distribution:'We launched our official global website and our Cooknjoy platform on September 2021 as we warm up for TOKIT global distribution.',
        soon:'joyami Coming Soon',
        cooker:'We will launch our TOKIT sub-brand, joyami at the end of 2021. Here is a preview of what joyami has to offer with our new rice cooker.',
        define:'Define Cooking Attitude With Temperature',
        creating:'Creating Reliable Technology That Enriches Lives',
        staffed:'Staffed by 300 elites from global tech giants, Apple, IBM, Philips, and many others, with the R&amp;D team accounting for 65%. We have more than 800 patents so far and are equipped with internet genetics and advanced manufacturing technology.'
    },
    creation:{
        edit:'Modify recipe',
        delete:'Delete recipe',
        delTitle:'Deleting recipe',
        delDesc:'Are you sure you want to delete this recipe?',
        btnCancle:'Cancel',
        btnDel:'Delete',
        delSuccess:'Recipe deleted!',
        delFailed:'Deletion failed, please try again later.',
        addRecipe:'START ADDING YOUR RECIPES',
        addDesc:'Creating and editing recipes is so easy, come and try it!',
        publishRecipe:'Upload recipe',
        reviewing:'Under review',
        reviewFailed:'Failed the review',
    },
    userRecipe: {
        createdBy: "Created by",
        done: "DONE",
        uploadCoverImg: "Upload cover image",
        changeCoverImg: "Change cover image",
        browser: "Browse",
        uploadTips: "Image formats supported: jpg, jpeg, png, bmp, webp; within 2MB; recommended size: ",
        uploadLimitTip: "The image size exceeds the 2MB limit. Please select again.",
        confirm1: "Confirm",
        reselect: "Reselect",
        uploadFail: "Failed to upload cover image, please try again later.",
        recipeName: "Recipe Name",
        inputRecipeName: 'Please enter the recipe name',
        recipeNameTips: 'The name does not currently support special characters.',
        description: "Recipe Description",
        inputDesc: 'Please enter a description (optional)',
        moreInfo: "More information",
        supportProduct: "Compatible products",
        difficulty: "Difficulty",
        prepareTime: "Preparation time",
        totalTime: "Total time",
        portion: "Portion",
        productTips: "Currently, only recipe creation for this product is supported. More product creation functions are coming online...",
        easy: "Easy",
        medium: "Medium",
        difficult: "Difficult",
        hour: "hours",
        minute: "minutes",
        cancel: "Cancel",
        confirm: "Sure",
        setTimeLimit24: "Set the duration limit to 24 hours",
        peopleLimit20: "Set the upper limit of 20 people/portions",
        selectDifficult: "Please select the cooking difficulty",
        setPrepareTime: "Please select preparation time",
        setTotalTime: "Please select total time",
        setPeopleNum: "Please select the number of people dining",
        ingredients: "Ingredients",
        addIngredients: "ADD INGREDIENTS",
        ingredientLimitTips: "You can add up to 20 kinds of ingredients.",
        steps: "Steps",
        addSteps: "ADD STEPS",
        stepLimitTips: "You can add up to 15 steps.",
        uploadStepImg: "Upload step image",
        changeStepImg: "Change step image",
        inputStepDesc: "Please enter a step description",
        stepParams: "Step parameters",
        second: "seconds",
        timeLimitTips: "Set the maximum duration to 99 minutes",
        timesAlreadyEdit: 'The duration has been adjusted to a valid combination for you',
        inputTemp: "Temperature",
        tempTips: "The temperature range is 35-180℃, and can only be adjusted in 5 degrees",
        tempOff: "Temperature off",
        tempOn: "Temperature on",
        tempAlreadyEdit: "The temperature has been adjusted to the effective combination for you",
        selectSpeed: "Please select speed",
        positiveSpeed: "Positive speed",
        reverseSpeed: "Reverse speed",
        tips: "Tips",
        inputTips: "Please enter tips (optional)",
        label: "Tags",
        addLabel: "ADD TAGS",
        viewMore: "View More",
        coverImgerror: "Please add a recipe cover image",
        recipeNameError: "Please enter the recipe name",
        ingredientError: "Please add at least 1 ingredient.",
        stepError: "Please add at least 1 step.",
        labelError: "Please add at least 1 category.",
        ingredientNameError: "The ingredient name cannot be empty.",
        stepDescError: "Step description cannot be empty.",
        ingredientName: "water",
        ingredientUnit: "100g",
        simpleHour: 'h',
        simpleMin: 'min'
    }
}