/**
 * 马来语
 */
export default{
    header:{
        Popular:'Popular',
        collections:'Koleksi Terkini',
        store:'Kedai',
        about:'Mengenai TOKIT',
        account:'Akaun saya',
        collection:'Koleksi saya',
        creation:'Ciptaan saya',
        device:'Peranti saya',
        signIn:'Log masuk',
        loginOut:'Log keluar'
    },
    fotter:{
        privacy:'Dasar Privasi',
        terms:'Perjanjian Pengguna',
        reserved:'TOKIT 2020 tokit.com Hak cipta terpelihara.'
    },
    home:{
        featuredrecipes:'Resipi Pilihan',
        more:'Ketahui Lebih Lanjut',
        collections:'Koleksi Popular',
        delicacy:'Kelazatan Pilihan',
        collection:'Koleksi Hari ini',
        latestRecipes:'Resipi Terkini',
        cookbook:'Cari',
        search:'Cari',
        link:'Pautan cepat',
        getTheDelicacies: 'Dapatkan juadahnya',
        gainSomeInspirations: "Dapatkan beberapa Inspirasi"
    },
    recipesearch:{
        noresult:'Tiada hasil ditemui untuk carian yang dipilih.',
        viewMore:'Lihat lagi',
        collection:'Resipi ditambah ke kegemaran!',
        recipe:'Resipi telah dialih keluar daripada kegemaran!',
        noMore: 'Tiada data lagi!',
        newest: 'Terbaru',
        shortest: 'Paling Popular',
        recipes: 'Resepi',
        collections: 'Koleksi',
        tags: 'Tag',  
        products: 'Kategori',
        category: 'Kategori',
        showResults: 'Tunjukkan keputusan',
        Filters: 'Penapis', 
        showAll: 'Tunjukkan semua',
        hideAll: 'Runtuh',
    },
    specialTopicList:{
        explore:'Terokai Koleksi Terkini kami',
        viewAll: 'Lihat Semua',
        Recipes:'resipi'
    },
    login:{
        logs:'Log masuk ke Akaun Anda',
        email:'Alamat e-mel',
        psd:'Kata laluan',
        by:'Dengan log masuk, anda bersetuju untuk:',
        useragreement:'Perjanjian Pengguna',
        and:'dan',
        login:'Log masuk',
        or:'atau',
        createaccount:'Buat Akaun',
        forgotpassword:'Terlupa kata laluan',
        reserved:'© 2021, Chunmi Technology (Hong Kong) Limited. Semua hak terpelihara.',
        agree:'Saya setuju',
        privacy:'Sila baca dan bersetuju dengan dasar kami.',
        enterpass:'Masukkan e-mel anda untuk menerima arahan tentang cara menetapkan semula kata laluan anda.',
        exist:'Akaun tidak wujud.',
        later:'Kesilapan telah berlaku, sila cuba lagi kemudian.',
        reset:'Tetapkan semula',
        return:'Atau kembali ke',
        denglu:'Log masuk',
        resetpsd:'Tetapkan Semula Kata Laluan',
        should:'Anda akan menerima pautan dalam beberapa saat. Sila buka pautan itu untuk menetapkan semula kata laluan anda.',
        backIn:'Kembali untuk log masuk',
        setpad:'Tetapkan kata laluan',
        minimum:'Minimum 8 aksara, sekurang-kurangnya satu huruf besar, satu huruf kecil dan satu nombor',
        confirm:'Mengesahkan',
        confirmpsd:'Sahkan Kata Laluan',
        passwordMismatch:'Dua kata laluan tidak sepadan',
        incorrect :'Format kata laluan tidak betul',
        valids:'Sila berikan kata laluan yang sah.',
        setName:'Tetapkan nama pengguna anda',
        tip:'Nama panggilan perlu unik, dalam bahasa Cina dan Inggeris, dan tidak mengandungi aksara khas.',
        wrong:'Kesilapan telah berlaku, sila cuba lagi kemudian.',
        usName:'Nama pengguna sudah diambil.',
        save:'Jimat',
        validmail:'Sila berikan alamat e-mel yang sah.',
        validpassword:'Sila berikan kata laluan yang sah.',
        validname:'Sila berikan nama yang sah',
        loginReminder:'Peringatan log masuk',
        format:'Sila padankan format yang diminta'
    },
    recipeDetails:{
        difficulty:'Kesukaran',
        serves:'Hidangkan',
        preparationtime:'Masa penyediaan',
        totaltime:'Jumlah masa',
        ingredients:'Bahan-bahan',
        preparation:'Langkah',
        relatedrecipes:'Resipi Berkaitan',
        min:'minit',
        reterecipe:'Nilaikan resipi ini',
        evaluationTips:'Bagaimanakah anda menilai resipi ini daripada 1 bintang (sangat teruk) kepada 5 bintang (sangat baik)?',
        angry:'marah',
        disappointed:'kekecewaan',
        average:'secara amnya',
        satisfied:'memuaskan',
        vsatisfied:'Sangat berpuas hati',
        clear:'Batal',
        saveRecipes: 'Resipi penanda buku',
        removeRecipes: 'Batalkan kegemaran',
        supportedProducts: 'Produk yang serasi',
        tags: 'Tag',
        tips:'Petua',
        edit: 'Sunting',
        Duration:'Tempoh',
        Temperature:'Suhu',
        Speed:'Kelajuan',
        corotation:'Kelajuan positif',
        reversal:'Kelajuan terbalik',
        minutes:'minit',
        easy:'Mudah',
        Medium:'Sederhana',
        Difficult:'Sukar',
        title:'Butiran Resipi',
        positive:'Kelajuan positif',
        reverse:'Kelajuan terbalik',
        needHour:'jam',
        needMin:'minit',
        
    },
    account:{
        hi:'helo,',
        profile:'profil saya',
        collection:'koleksi saya',
        creation:'Ciptaan saya',
        device:'peranti saya',
        editprofile:'Edit profil',
        firstname:'nama',
        lastname:'nama keluarga',
        gender:'jantina',
        birthday:'hari jadi',
        country:'Negara/Wilayah',
        email:'email',
        phonenum:'nombor telefon',
        bundle:'Himpunkan peranti TOKIT anda untuk menikmati perkhidmatan yang lebih baik',
        addvice:'Tambah peranti',
        bingdevice:'Ikat peranti',
        product:'produk',
        blank:'Produk tidak boleh kosong',
        deviceNo:'Nombor peranti',
        modelBlank:'Model tidak boleh kosong',
        submit:'menyerahkan',
        guide:'Panduan melihat nombor peralatan',
        content:'Buka menu kandungan, klik Tetapan untuk melompat ke halaman, dan kemudian klik "Perihal" untuk melompat ke halaman untuk mencari peranti yang modelnya tidak ditunjukkan. Ketahui lebih lanjut tentang',
        fullTxt:'Proses pengikatan yang lengkap',
        adddevice:'tambah peranti saya',
        choose:'Sila pilih produk anda.',
        modelNum:'Model',
        personal:'maklumat peribadi',
        male:'lelaki',
        female:'perempuan',
        country:'Negara atau wilayah',
        year:'tahun',
        month:'bulan',
        day:'langit',
        complete:'Sila lengkapkan pengikatan pada peranti anda.',
        completed:'Selesai',
        noDevice:'Tiada pop timbul pada peranti saya',
        tryAlign:'cuba lagi',
        removeCollection:'Padamkan koleksi',
        doreally:'Adakah anda pasti mahu memadamkan koleksi ini?',
        firstRecipe:'Simpan resipi pertama anda!',
        theSave:'Klik simpan',
        icon:'ikon pada resipi untuk menyimpannya untuk kegunaan masa hadapan.',
        findRecipe:'Cari resipi',
        successfully:'Mengikat berjaya!',
        removedevice:'Peranti berjaya dialih keluar.',
        bindingFailed:'Pengikatan gagal',
        removeTOKIT: 'Padam TOKIT',
        removeTips: 'Adakah anda benar-benar mahu menyahaktifkan TOKIT anda?',
        cancel: 'Batal',
        remove: 'padam',
		loadTip:'Muat turun APP dan ikat peranti'
    },
    aboutus:{
        seek:'apa yang kita cari',
        helping:'Membantu memasak lebih mudah',
        journey:'Memasak kini boleh menjadi perjalanan yang santai dan menyeronokkan.',
        providing:'Menyediakan penyelesaian memasak pintar',
        continuing:'Teruskan menyelidik dan membangunkan pengalaman dapur yang paling bijak.',
        database:'Perpustakaan masakan berasaskan awan',
        building:'Bina menu yang lebih luas dan imaginatif untuk pengguna.',
        history:'sejarah',
        previous:'Sebelumnya',
        next:'Seterusnya',
        ourJourney:'Perjalanan kami bermula pada bulan Mei',
        established:"Diasaskan pada Mei 2013, Teknologi TOKIT melancarkan percikan dan mula mengusahakan pembangunan dan aplikasi Internet Perkara dan peralatan awan.",
        ourfirst:'Produk pertama yang kami lancarkan',
        intend:'Untuk mentakrifkan semula perkakas dapur mengikut keperluan teknologi produk elektronik, kami bermula dengan periuk elektrik untuk menaik taraf dan memperbaharui.',
        awards:'Anugerah yang kami terima sejak 2020',
        since:'Sejak penubuhan kami, kami telah diiktiraf oleh banyak organisasi, daripada IF, Red',
        launched:'Prototaip TOKIT dikeluarkan',
        developing:'Kami membangunkan prototaip TOKIT sepanjang 2020, disenaraikan sebagai T0, T1 dan T2',
        Kickstarter:'$1,142,227 pada Summer Kickstarter',
        reached:'Apabila TOKIT Omni Cook kami dilancarkan secara rasmi secara global, 1,566 penyokong pada kickerstarter dan jualan mencecah $1,142,227',
        website:'website And Cooknjoy Akan datang September',
        distribution:'Kami melancarkan laman web rasmi global dan platform Cooknjoy pada September 2021 untuk menyediakan pengedaran global TOKIT.',
        Soon:'joyami akan datang tidak lama lagi',
        cooker:'Kami akan melancarkan joyami sub-jenama TOKIT kami pada penghujung tahun 2021. Di bawah ialah pratonton periuk elektrik baharu kami daripada Joyami.',
        define:'Menggunakan suhu untuk menentukan sikap memasak',
        creating:'Teknologi yang boleh dipercayai untuk memperkayakan kehidupan',
        staffed:'Terdiri daripada 300 elit daripada gergasi teknologi global Apple, IBM, Philips dan banyak syarikat lain menyumbang 65%. Setakat ini, kami mempunyai lebih daripada 800 paten dan dilengkapi dengan genetik Internet dan teknologi pembuatan termaju'
    },
    creation:{
        edit:'Ubah suai resipi',
        delete:'Padam resipi',
        delTitle:'Memadam resipi',
        delDesc:'Adakah anda pasti mahu memadamkan resipi ini?',
        btnCancle:'Batal',
        btnDel:'Padam',
        delSuccess:'Resipi dipadamkan!',
        delFailed:'Pemadaman gagal, sila cuba lagi kemudian.',
        addRecipe:'MULAKAN MENAMBAH RESEPI ANDA',
        addDesc:'Mencipta dan menyunting resipi adalah sangat mudah, datang dan cuba!',
        publishRecipe:'Muat naik resipi',
        reviewing:'Dalam semakan',
        reviewFailed:'Gagal semakan',
    },
    userRecipe: {
        createdBy: "Dibuat oleh",
        done: "SELESAI",
        uploadCoverImg: "Muat naik imej muka depan",
        changeCoverImg: "Tukar imej muka depan",
        browser: "Semak imbas",
        uploadTips: "Format imej disokong: jpg, jpeg, png, bmp, webp; dalam 2MB; saiz yang disyorkan: ",
        uploadLimitTip: "Saiz imej melebihi had 2MB. Sila pilih semula.",
        confirm1: "Mengesahkan",
        reselect: "Pilih semula",
        uploadFail: "Gagal memuat naik imej muka depan, sila cuba sebentar lagi.",
        recipeName: "Nama Resipi",
        inputRecipeName: 'Sila masukkan nama resipi',
        recipeNameTips: 'Nama itu tidak menyokong aksara khas pada masa ini.',
        description: "Penerangan Resipi",
        inputDesc: 'Sila masukkan perihalan (pilihan)',
        moreInfo: "Maklumat lanjut",
        supportProduct: "Produk yang serasi",
        difficulty: "Kesukaran",
        prepareTime: "Masa penyediaan",
        totalTime: "Jumlah masa",
        portion: "Hidangkan",
        productTips: "Pada masa ini, hanya penciptaan resipi untuk produk ini disokong. Lebih banyak fungsi penciptaan produk akan datang dalam talian...",
        easy: "Mudah",
        medium: "Sederhana",
        difficult: "Sukar",
        hour: "jam",
        minute: "minit",
        cancel: "Batal",
        confirm: "Pasti",
        setTimeLimit24: "Tetapkan had tempoh kepada 24 jam",
        peopleLimit20: "Tetapkan had atas 20 orang/bahagian",
        selectDifficult: "Sila pilih kesukaran memasak",
        setPrepareTime: "Sila pilih masa penyediaan",
        setTotalTime: "Sila pilih jumlah masa",
        setPeopleNum: "Sila pilih bilangan orang yang makan",
        ingredients: "Bahan-bahan",
        addIngredients: "TAMBAHKAN BAHAN",
        ingredientLimitTips: "Anda boleh menambah sehingga 20 jenis bahan.",
        steps: "Langkah",
        addSteps: "TAMBAH LANGKAH",
        stepLimitTips: "Anda boleh menambah sehingga 15 langkah.",
        uploadStepImg: "Muat naik imej langkah",
        changeStepImg: "Tukar imej langkah",
        inputStepDesc: "Sila masukkan penerangan langkah",
        stepParams: "Parameter langkah",
        second: "detik",
        timeLimitTips: "Tetapkan tempoh maksimum kepada 99 minit",
        timesAlreadyEdit: 'Tempoh telah dilaraskan kepada gabungan yang sah untuk anda',
        inputTemp: "Suhu",
        tempTips: "Julat suhu ialah 35-180 ℃, dan hanya boleh dilaraskan dalam 5 darjah",
        tempOff: "Suhu dimatikan",
        tempOn: "Suhu dihidupkan",
        tempAlreadyEdit: "Suhu telah dilaraskan kepada gabungan yang berkesan untuk anda",
        selectSpeed: "Sila pilih kelajuan",
        positiveSpeed: "Kelajuan positif",
        reverseSpeed: "Kelajuan terbalik",
        tips: "Petua",
        inputTips: "Sila masukkan petua (pilihan)",
        label: "Tag",
        addLabel: "TAMBAH TAG",
        viewMore: "Lihat Lagi",
        coverImgerror: "Sila tambahkan gambar muka depan resipi",
        recipeNameError: "Sila masukkan nama resipi",
        ingredientError: "Sila tambah sekurang-kurangnya 1 bahan.",
        stepError: "Sila tambah sekurang-kurangnya 1 langkah.",
        labelError: "Sila tambah sekurang-kurangnya 1 kategori.",
        ingredientNameError: "Nama bahan tidak boleh kosong.",
        stepDescError: "Penerangan langkah tidak boleh kosong.",
        ingredientName: "air",
        ingredientUnit: "100g",
        simpleHour: 'jam',
        simpleMin: 'minit'
    }
}