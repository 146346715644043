/**
 * 西班牙
 */
 export default{
    header:{
        Popular:'Amado',
        collections:'Última colección',
        store:'Tienda',
        about:'Sobre TOKIT',
        account:'Mi cuenta',
        collection:'Mis favoritos',
        creation:'Subir receta',
        device:'Mi equipo',
        signIn:'Iniciar sesión',
        loginOut:'Cancelar'
    },
    fotter:{
        privacy:'Políticas de privacidad',
        terms:'Términos de servicio',
        reserved:'Todos los derechos reservados: tokit2020'
    },
    home:{
        featuredrecipes:'Menú',
        more:'Conocer más',
        collections:'Colección popular',
        delicacy:'Gastronomía especial',
        collection:'Favoritos diarios',
        latestRecipes:'Últimas recetas',
        cookbook:'Buscar',
        search:'Buscar',
        link:'Enlace rápido',
        getTheDelicacies: 'Obtener gastronomía',
        gainSomeInspirations: "Obtener algunas inspiraciones"
    },
    recipesearch:{
        noresult:'No es encontrado el resultado de la búsqueda hecha.',
        viewMore:'Ver más',
        collection:'¡La colección es exitosamente renovada!',
        recipe:'¡La receta es suprimida de los favoritos!',
        noMore: 'No hay más datos todavía!',
        newest: 'El más nuevo',
        shortest: 'Más Popular',
        recipes: 'Recetas',
        collections: 'Colecciones',
        tags: 'Etiquetas', 
        products: 'Producto',
        category: 'Categoría',
        showResults: 'Mostrar resultados',
        Filters: 'Filtros', 
        showAll: 'Mostrar todo',
        hideAll: 'Colapsar',
    },
    specialTopicList:{
        explore:'Buscar nuestra última serie',
        viewAll: 'Consultar todo',
        Recipes:'recetas'
    },
    login:{
        logs:'Iniciar sesión en su cuenta',
        email:'Dirección de correo electrónico',
        psd:'Contraseña',
        by:'A través de inicio de sesión, usted acuerda:',
        useragreement:'Acuero de usuario',
        and:'Y',
        login:'Iniciar sesión',
        or:'O',
        createaccount:'Crear cuenta',
        forgotpassword:'Contraseña olvidada',
        reserved:'©2021, Chunmi Technology (Hong Kong) Limited Todos los derechos reservados.',
        agree:'Estoy de acuerdo',
        privacy:'Por favor lea y acuerde nuestras políticas.',
        enterpass:'Introduzca su dirección de correo electrónico para recibir las instrucciones sobre cómo reconfigurar contraseña.',
        exist:'Esta cuenta no existe.',
        later:'Hay problema, por favor reintente más tarde.',
        reset:'Reconfigurar',
        return:'O retornar hasta',
        denglu:'Iniciar sesión',
        resetpsd:'Reconfigurar contraseña',
        should:'Usted recibirá un enlace en unos minutos. Por favor abra este enlace para reconfigurar contraseña.',
        backIn:'Retornar para iniciar sesión',
        setpad:'Configurar contraseña',
        minimum:'Tiene al menos 8 caracteres que contienen al menos una mayúscula, una minúscula y una cifra',
        confirm:'Autenticar',
        confirmpsd:'Confirmar contraseña',
        passwordMismatch:'Las dos contraseñas no son coincidentes',
        incorrect :'El formato de contraseña es incorrecto',
        valids:'Por favor proporcione una contraseña válida.',
        setName:'Configure su nombre de usuario',
        tip:'El apodo debe ser único y estar en chino e inglés, y no puede contener caracteres especiales',
        wrong:'Hay problema, por favor reintente más tarde.',
        usName:'El nombre de usuario es usado.',
        save:'Guardar',
        validmail:'Por favor proporcione una dirección de correo electrónico válida.',
        validpassword:'Por favor proporcione una contraseña válida.',
        validname:'Por favor proporcione un nombre válido',
        loginReminder:'Advertencia para iniciar sesión',
        format:'Por favor respete el formato solicitado'
    },
    recipeDetails:{
        difficulty:'Dificultad',
        serves:'Servicio',
        preparationtime:'Tiempo de preparación',
        totaltime:'Tiempo total',
        ingredients:'Ingredientes',
        preparation:'Preparar',
        relatedrecipes:'Receta pertinente',
        min:'Minuto',
        reterecipe:'Evaluar esta receta',
        evaluationTips:'De 1 estrella (muy mala) a 5 estrellas (muy buena), ¿cómo usted evalúa esta receta?',
        angry:'Enfadado',
        disappointed:'Decepcionado',
        average:'Normal',
        satisfied:'Satisfactorio',
        vsatisfied:'Muy satisfactorio',
        clear:'Cancelar',
        saveRecipes: 'Guardar la receta',
        removeRecipes: 'Eliminar una receta',
        supportedProducts: 'Productos compatibles',
        tags: 'Etiquetas',
        tips:'Consejos',
        edit: 'Editar',
        Duration:'Duración',
        Temperature:'Temperatura',
        Speed:'Velocidad',
        corotation:'',
        reversal:'-',
        minutes:'Minuten',
        easy:'Fácil',
        Medium:'Medio',
        Difficult:'Difícil',
        title:'Detalles de la receta',
        positive:'Positive speed',
        reverse:'Reverse speed',
        needHour:'Stunden',
        needMin:'Minuten',
    },
    account:{
        hi:'Hola,',
        profile:'Mis datos personales',
        collection:'Mis favoritos',
        creation:'Mis creaciones',
        device:'Mi equipo',
        editprofile:'Editar archivos de configuración',
        firstname:'Nombre',
        lastname:'Apellido',
        gender:'Sexo',
        birthday:'Fecha de nacimiento',
        country:'País/región',
        email:'email',
        phonenum:'Número de teléfono',
        bundle:'Vincule su equipo TOKIT para gozar de mejor servicio',
        addvice:'Agregar equipo',
        bingdevice:'Vincular equipo',
        product:'Producto',
        blank:'El producto no puede estar vacío',
        deviceNo:'Número serial de equipo',
        modelBlank:'El modelo no puede estar vacío',
        submit:'Entregar',
        guide:'Guía de consulta del número serial de equipo',
        content:'Abra el menú de contexto, haga un clic en Configurar para pasar a la página, y luego, haga un clic en "Sobre" para pasar a esta página, a fin de buscar el equipo cuyo modelo no está visualizado. Conozca más sobre',
        fullTxt:'Proceso completo de vinculación',
        adddevice:'Agregar mi equipo',
        choose:'Por favor seleccione su producto.',
        modelNum:'Modelo',
        personal:'Información personal',
        male:'Hombre',
        female:'Mujer',
        country:'País o región',
        year:'Año',
        month:'Mes',
        day:'Día',
        complete:'Por favor complete la vinculación en su equipo.',
        completed:'Completar',
        noDevice:'No hay ventana emergente en mi equipo',
        tryAlign:'Probar otra vez',
        removeCollection:'Suprimir colección',
        doreally:'¿Está seguro de que quiere suprimir esta colección?',
        firstRecipe:'¡Guarde su primera receta!',
        theSave:'Pulse Guardar',
        icon:'En el icono de la receta, para conservarla para el uso futuro.',
        findRecipe:'Buscar receta',
        successfully:'¡Vinculación exitosa!',
        removedevice:'El equipo es borrado exitosamente.',
        bindingFailed:'Vinculación fallada',
        removeTOKIT: 'Eliminar totkit',
        removeTips: '¿De verdad quieres desactivar tu tokit?',
        cancel: 'Cancelar',
        remove: 'Borrar',
		loadTip:'Descarga la aplicación para vincular dispositivos'
    },
    aboutus:{
        seek:'Qué buscamos',
        helping:'Ayudar a hacer más fácil la cocina',
        journey:'La cocina puede ser actualmente un viaje relajante y agradable',
        providing:'Proporcionar solución de cocina inteligente',
        continuing:'Seguir investigando y desarrollando la experiencia de cocina más inteligente.',
        database:'Base de datos de cocina basada en la nube',
        building:'Crear para el usuario un menú más ampliado e imaginativo',
        history:'Historia',
        previous:'Procedente',
        next:'Próximo',
        ourJourney:'Nuestro viaje inicia desde mayo',
        established:"Fundada en mayo de 2013, TOKIT Technology inicia spark y empieza a dedicarse al desarrollo y aplicación del Internet de las cosas y de los aparatos electrodomésticos en la nube.",
        ourfirst:'Primer producto que lanzamos',
        intend:'A propósito de definir de nuevo los utensilios de cocina según los requisitos tecnológicos de productos electrónicos, comenzamos la actualización y reforma a partir de la olla eléctrica de arroz.',
        awards:'Premios que hemos obtenidos a partir de 2020',
        since:'A partir de la fundación, hemos aprobado la certificación de muchas organizaciones, y hemos adquirido 48 premios otorgados por los órganos tales como IF, Red Dot, G-Mark, IDEA.',
        launched:'Lanzamiento de prototipos de TOKIT',
        developing:'Desarrollamos los prototipos de TOKIT durante todo el año 2020, denominados como T0, T1 y T2',
        Kickstarter:'$1.142.227 en Kickstartor de verano',
        reached:'Con el lanzamiento formal mundial de nuestro TOKIT Omni Cook, kickerstarter cuenta con 1566 partidarios, y el importe de venta llega hasta 1.142.227 dólares',
        website:'website And Cooknjoy Septiembre que llegará pronto',
        distribution:'Lanzamos en septiembre de 2021 el sitio web oficial mundial y la plataforma Cooknjoy, para hacer preparaciones para la distribución mundial de TOKIT.',
        Soon:'Joyami llegará pronto',
        cooker:'Lanzaremos a finales de 2021 nuestra submarca joyami de TOKIT. A continuación está la vista previa suministrada por joyami para nuestra nueva olla eléctrica de arroz.',
        define:'Definir con temperatura la actitud de cocina',
        creating:'Tecnología fiable para crear vida abundante',
        staffed:'Composición de 300 personas de élite de los gigantes tecnológicos mundiales Apple, IBM, Philips y muchas otras compañías; el equipo D ocupa el 65%. Hasta la fecha, contamos con más de 800 patentes y estamos equipados con genética de Internet y tecnologías de manufactura avanzadas'
    },
    creation:{
        edit:'Modificar receta',
        delete:'Eliminar receta',
        delTitle:'Eliminar receta',
        delDesc:'¿Estás seguro que deseas eliminar esta receta?',
        btnCancle:'Cancelar',
        btnDel:'Borrar',
        delSuccess:'¡Receta eliminada!',
        delFailed:'La eliminación falló, inténtelo nuevamente más tarde.',
        addRecipe:'EMPIEZA A AGREGAR TUS RECETAS',
        addDesc:'¡Crear y editar recetas es muy fácil, ven y pruébalo!',
        publishRecipe:'Subir receta',
        reviewing:'En revisión',
        reviewFailed:'Falló la revisión',
    },
    userRecipe: {
        createdBy: "Creado por",
        done: "Hecho",
        uploadCoverImg: "Subir imagen de portada",
        changeCoverImg: "Cambiar la imagen de portada",
        browser: "Navegar",
        uploadTips: "Formatos de imagen admitidos: jpg, jpeg, png, bmp, webp; hasta 2 MB; tamaño recomendado: ",
        uploadLimitTip: "El tamaño de la imagen supera el límite de 2 MB. Seleccione nuevamente.",
        confirm1: "Confirmar",
        reselect: "Volver a seleccionar",
        uploadFail: "No se pudo cargar la imagen de portada, inténtelo nuevamente más tarde.",
        recipeName: "Nombre de la receta",
        inputRecipeName: 'Por favor ingrese el nombre de la receta',
        recipeNameTips: 'El nombre actualmente no admite caracteres especiales.',
        description: "Descripción de la receta",
        inputDesc: 'Por favor ingrese una descripción (opcional)',
        moreInfo: "Más información",
        supportProduct: "Productos compatibles",
        difficulty: "Dificultad",
        prepareTime: "Tiempo de preparación",
        totalTime: "Tiempo total",
        portion: "Sirve",
        productTips: "Actualmente, solo se admite la creación de recetas para este producto. Pronto estarán disponibles más funciones de creación de productos...",
        easy: "Fácil",
        medium: "Medio",
        difficult: "Difícil",
        hour: "horas",
        minute: "minutos",
        cancel: "Cancelar",
        confirm: "Por supuesto",
        setTimeLimit24: "Establezca el límite de duración en 24 horas.",
        peopleLimit20: "Establezca el límite superior de 20 personas/porciones",
        selectDifficult: "Por favor seleccione la dificultad de cocción",
        setPrepareTime: "Por favor seleccione el tiempo de preparación",
        setTotalTime: "Por favor seleccione el tiempo total",
        setPeopleNum: "Por favor seleccione el número de personas que cenarán",
        ingredients: "Ingredientes",
        addIngredients: "AGREGAR INGREDIENTES",
        ingredientLimitTips: "Puedes agregar hasta 20 tipos de ingredientes.",
        steps: "Pasos",
        addSteps: "AGREGAR PASOS",
        stepLimitTips: "Puedes agregar hasta 15 pasos.",
        uploadStepImg: "Subir imagen de paso",
        changeStepImg: "Cambiar la imagen del paso",
        inputStepDesc: "Por favor ingrese una descripción del paso",
        stepParams: "Parámetros de paso",
        second: "artículos de segunda clase",
        timeLimitTips: "Establezca la duración máxima en 99 minutos.",
        timesAlreadyEdit: 'La duración se ha ajustado a una combinación válida para usted.',
        inputTemp: "Temperatura",
        tempTips: "El rango de temperatura es de 35 a 180 ℃ y solo se puede ajustar en 5 grados.",
        tempOff: "Temperatura apagada",
        tempOn: "Temperatura encendida",
        tempAlreadyEdit: "La temperatura se ha ajustado a la combinación efectiva para usted.",
        selectSpeed: "Por favor seleccione la velocidad",
        positiveSpeed: "Positive speed",
        reverseSpeed: "Reverse speed",
        tips: "Consejos",
        inputTips: "Por favor ingrese propinas (opcional)",
        label: "Etiquetas",
        addLabel: "AGREGAR ETIQUETAS",
        viewMore: "Ver más",
        coverImgerror: "Por favor agregue una imagen de portada de receta",
        recipeNameError: "Por favor ingrese el nombre de la receta",
        ingredientError: "Por favor agregue al menos 1 ingrediente.",
        stepError: "Por favor agregue al menos 1 paso.",
        labelError: "Por favor agregue al menos 1 categoría.",
        ingredientNameError: "El nombre del ingrediente no puede estar vacío.",
        stepDescError: "La descripción del paso no puede estar vacía.",
        ingredientName: "agua",
        ingredientUnit: "100 gramos",
        simpleHour: 'h',
        simpleMin: 'minuto'
    }
}