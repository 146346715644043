/**
 * 波兰语
 */
export default{
    header:{
        Popular:'Popularne',
        collections:'Najnowsze kolekcje',
        store:'Sklep',
        about:'O TOKIT',
        account:'Moje konto',
        collection:'Moja kolekcja',
        creation:'Moje dzieła',
        device:'Moje urządzenie',
        signIn:'Zaloguj się',
        loginOut:'Wyloguj się'
    },
    fotter:{
        privacy:'Polityka prywatności',
        terms:'Warunki korzystania z serwisu',
        reserved:'TOKIT 2020 tokit.com Wszelkie prawa zastrzeżone.'
    },
    home:{
        featuredrecipes:'Polecane przepisy',
        more:'Dowiedz się więcej',
        collections:'Popularne kolekcje',
        delicacy:'Polecane przysmaki',
        collection:'Kolekcja dnia',
        latestRecipes:'Najnowsze przepisy',
        cookbook:'Szukaj',
        search:'Szukaj',
        link:'Szybkie łacza',
        getTheDelicacies: 'Zdobądź przysmaki',
        gainSomeInspirations: "Zainspiruj się"
    },
    recipesearch:{
        noresult:'Nie znaleziono żadnych wyników wyszukiwania dla wybranej frazy.',
        viewMore:'Zobacz więcej',
        collection:'Kolekcja została pomyślnie zaktualizowana!',
        recipe:'Przepis został usunięty z kolekcji!',
        noMore: 'Brak danych!',
        newest: 'Najnowsze',
        shortest: 'Najpopularniejsze',
        recipes: 'Przepisy',
        collections: 'Kolekcje',
        tags: 'Tagi',  
        products: 'Produkt',
        category: 'Kategoria',
        showResults: 'Pokaż wyniki',
        Filters: 'Filtry', 
        showAll: 'Pokaż wszystkie',
        hideAll: 'Zwiń',
        
    },
    specialTopicList:{
        explore:'Przeglądaj nasze najnowsze kolekcje',
        viewAll: 'Zobacz wszystko',
        Recipes:'Przepisy'
    },
    login:{
        logs:'Zaloguj się do swojego konta',
        email:'Adres email',
        psd:'Hasło',
        by:'Logując się, wyrażasz zgodę na',
        useragreement:'Umowa użytkownika',
        and:'i',
        login:'Login',
        or:'lub',
        createaccount:'Utwórz konto',
        forgotpassword:'Zapomniałem/-łam hasła',
        reserved:'© 2021, Chunmi Technology (Hong Kong) Limited. Wszelkie prawa zastrzeżone.',
        agree:'Wyrażam zgodę na',
        privacy:'Prosimy o zapoznanie się i zaakceptowanie naszej polityki.',
        enterpass:'Wpisz swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła.',
        exist:'Konto nie istnieje.',
        later:'Coś poszło nie tak, spróbuj ponownie później.',
        reset:'Resetuj',
        return:'lub powróć do',
        denglu:'Zaloguj się',
        resetpsd:'Resetuj hasło',
        should:'Za kilka chwil powinieneś otrzymać link. Proszę otwórz go w celu zresetowania swojego hasła.',
        backIn:'Powrót do logowania',
        setpad:'Ustaw swoje hasło',
        minimum:'Minimum 8 znaków, co najmniej jedna wielka litera, jedna mała litera i jedna cyfra',
        confirm:'Potwierdź',
        confirmpsd:'Potwierdź hasło',
        passwordMismatch:'Niezgodność hasła',
        incorrect :'Nieprawidłowy format hasła',
        valids:'Proszę podać prawidłowe hasło.',
        setName:'Ustaw swoją nazwę użytkownika',
        tip:'Pseudonimy powinny być unikalne, w języku chińskim i angielskim i nie powinny zawierać znaków specjalnych',
        wrong:'Coś poszło nie tak, spróbuj ponownie później.',
        usName:'Nazwa użytkownika została już zajęta.',
        save:'Zapisz',
        validmail:'Proszę podać prawidłowy adres email.',
        validpassword:'Proszę podać prawidłowe hasło.',
        validname:'Proszę podać prawidłową nazwę użytkownika.',
        format:'Proszę do dopasowanie do wymaganego formatu',
        loginReminder:'Przypomnienie o logowaniu',
    },
    recipeDetails:{
        difficulty:'Trudność',
        serves:'Serwis',
        preparationtime:'Czas przygotowania',
        totaltime:'Całkowity czas',
        ingredients:'Składniki',
        preparation:'Przygotowanie',
        relatedrecipes:'Powiązane przepisy',
        min:'min',
        reterecipe:'Oceń ten przepis',
        evaluationTips:'W skali od 1 gwiazdki (bardzo źle) do 5 gwiazdek (bardzo dobrze), jak oceniasz ten przepis?',
        angry:'zły',
        disappointed:'rozczarowany',
        average:'średni',
        satisfied:'zadowolony',
        vsatisfied:'bardzo zadowolony',
        clear:'ANULUJ',
        saveRecipes: 'Zapisz przepis',
        removeRecipes: 'Usuń przepis',
        supportedProducts: 'Kompatybilne produkty',
        tags: 'Tagi',
        tips:'Porady',
        edit: 'edytuj',
        Duration:'czas trwania',
        Temperature:'temperatura',
        Speed:'prędkość',
        corotation:'',
        reversal:'-',
        minutes:'minuta',
        easy:'proste',
        Medium:'wtórne',
        Difficult:'Trudniejsze',
        title:'Szczegóły przepisu',
        positive:'Obrót do przodu',
        reverse:'odwrócenie',
        needHour:'godzina',
        needMin:'minuty',
    },
    account:{
        hi:'Cześć,',
        profile:'Mój profil',
        collection:'Moja kolekcja',
        creation:'Moje dzieła',
        device:'Moje urządzenie',
        editprofile:'Edytuj profil',
        firstname:'Imię',
        lastname:'Nazwisko',
        gender:'Płeć',
        birthday:'Data urodzenia',
        country:'Kraj/Region',
        email:'Email',
        phonenum:'Numer telefonu',
        bundle:'Połącz swoje urządzenie TOKIT i ciesz się lepszymi usługami',
        addvice:'Dodaj urządzenie',
        bingdevice:'Powiązane urządzenia',
        product:'Produkt',
        blank:'Produkt nie może być pusty',
        deviceNo:'Numer urządzenia',
        modelBlank:'Numer modelu nie może być pusty.',
        submit:'PRZEŚLIJ',
        guide:'Przewodnik po numerze urządzenia',
        content:'Otwórz Menu zawartości, kliknij Ustawienia, aby przejść do strony, a następnie przejdź do strony, klikając O urządzeniu, aby znaleźć numer urządzenia wskazany przez numer modelu. Dowiedz się więcej o',
        fullTxt:'Pełny proces połączenia',
        adddevice:'Dodaj moje urządzenia',
        choose:'Proszę wybrać swój produkt.',
        modelNum:'Numer seryjny',
        personal:'Dane osobowe',
        male:'Mężczyzna',
        female:'Kobieta',
        country:'Kraj lub Region',
        year:'Rok',
        month:'Miesiąc',
        day:'Dzień',
        removeCollection:'Usuń kolekcję',
        doreally:'Czy na pewno chcesz usunąć tę kolekcję?',
        firstRecipe:'Zapisz swój pierwszy przepis!',
        theSave:'Stuknij ikonę zapisu',
        icon:'ikonę zapisu na przepisie, aby zapisać go na później.',
        findRecipe:'ZNAJDŹ PRZEPISY',
        complete:'Usuń kolekcję',
        completed:'Ukończone',
        tryAlign:'spróbuj ponownie',
        noDevice:'Brak wyskakujących okienek na moim urządzeniu',
        successfully:'Powiązanie zakończone sukcesem!',
        removedevice:'Urządzenie zostało usunięte pomyślnie.',
        bindingFailed:'Nie udało się powiązać',
        removeTOKIT: 'Usuń TOKIT',
        removeTips: 'Czy naprawdę chcesz wyłączyć swój TOKIT?',
        cancel: 'ANWELUJ',
        remove: 'USUŚĆ',
		loadTip:'Pobierz aplikację, aby powiązać urządzenia'
    },
    aboutus:{
        seek:'Czego szukamy',
        helping:'Ułatwienie gotowania',
        journey:'Gotowanie może być teraz relaksującym, przyjemnym zajęciem.',
        providing:'Inteligentne rozwiązania w zakresie gotowania',
        continuing:'Ciągłe badania i rozwój dla najbardziej inteligentnych doświadczeń związanych z kuchnią.',
        database:'Baza danych kulinarnych w chmurze',
        building:'Tworzenie bardziej rozbudowanego i pomysłowego menu dla użytkowników.',
        history:'Historia',
        previous:'Poprzedni',
        next:'Nastepny',
        ourJourney:'Nasza podróż rozpoczęła się w maju',
        established:"Założone w maju 2013 roku, TOKIT Technology dało początek swojej działalności, poświęcając się rozwojowi i implementacji urządzeń domowych opartych na technologii IOT oraz sprzętu AGD w chmurze.",
        ourfirst:'Nasz pierwszy produkt wypuszczony na rynek',
        intend:'Od początku istnienia zdobyliśmy uznanie wielu organizacji, zbierając 48 nagród od IF, Red Dot, G-Mark, IDEA i wielu innych.',
        awards:'Nagrody, które zdobyliśmy od 2020 roku',
        since:'Od początku istnienia zdobyliśmy uznanie wielu organizacji, zbierając 48 nagród od IF, Red Dot, G-Mark, IDEA i wielu innych.',
        launched:'Uruchomienie prototypów TOKIT',
        developing:'Przez cały 2020 rok rozwijaliśmy prototyp TOKIT T0, T1 i T2.',
        Kickstarter:'$1,142,227 na Kickstarterze w ciągu lata',
        reached:'Wraz z oficjalną globalną premierą naszego TOKIT Omni Cook osiągnęliśmy sprzedaż $1,142,227 od 1566 wspierających na kickerstarterze.',
        website:'Strona internetowa i Cooknjoy we wrześniu',
        distribution:'Uruchomiliśmy naszą oficjalną globalną stronę internetową i naszą platformę Cooknjoy we wrześniu 2021 roku, jako że przygotowujemy się do globalnej dystrybucji TOKIT.',
        soon:'joyami już wkrótce',
        cooker:'Pod koniec 2021 roku wprowadzamy na rynek naszą submarkę TOKIT, joyami. Oto zapowiedź tego, co joyami ma do zaoferowania z naszym nowym urządzeniem do gotowania ryżu.',
        define:'Określenie postawy kulinarnej za pomocą temperatury',
        creating:'Tworzenie niezawodnej technologii wzbogacającej życie',
        staffed:'Obsadzony przez 300 elitarnych jednostek z globalnych gigantów technologicznych, takich jak Apple, IBM, Philips i wielu innych, oraz z zespołem R&amp;D stanowiącym 65%, do tej pory mamy ponad 800 patentów i wykorzystujemy zaawansowaną technologię produkcyjną.'
    },
    creation:{
        edit:'Modyfikuj przepis',
        delete:'Usuń przepis',
        delTitle:'Usuwanie przepisu',
        delDesc:'Czy na pewno chcesz usunąć ten przepis?',
        btnCancle:'Anuluj',
        btnDel:'Usuń',
        delSuccess:'Przepis usunięty!',
        delFailed:'Nie udało się usunąć. Spróbuj ponownie później.',
        addRecipe:'ZACZNIJ DODAWAĆ SWOJE PRZEPISY',
        addDesc:'Tworzenie i edytowanie przepisów jest bardzo proste! Spróbuj!',
        publishRecipe:'Prześlij przepis',
        reviewing:'W trakcie sprawdzania',
        reviewFailed:'Niepowodzenie sprawdzania',
    },
    userRecipe: {
        createdBy: "Utworzone przez",
        done: "Gotowe",
        uploadCoverImg: "Prześlij obraz okładki",
        changeCoverImg: "Zmień obraz okładki",
        browser: "Przeglądać",
        uploadTips: "Obsługiwane formaty obrazów: jpg, jpeg, png, bmp, webp; do 2 MB; zalecany rozmiar: ",
        uploadLimitTip: "Rozmiar obrazu przekracza limit 2MB. Wybierz ponownie.",
        confirm1: "Potwierdź",
        reselect: "Wybierz ponownie",
        uploadFail: "Nie udało się przesłać obrazu okładki. Spróbuj ponownie później.",
        recipeName: "Nazwa przepisu",
        inputRecipeName: 'Wprowadź nazwę przepisu',
        recipeNameTips: 'Nazwa obecnie nie obsługuje znaków specjalnych.',
        description: "Opis przepisu",
        inputDesc: 'Proszę wpisać opis (opcjonalnie)',
        moreInfo: "Więcej informacji",
        supportProduct: "Zgodne produkty",
        difficulty: "Poziom trudności",
        prepareTime: "Czas przygotowania",
        totalTime: "Całkowity czas",
        portion: "Część",
        productTips: "Obecnie obsługiwane jest tylko tworzenie receptur dla tego produktu. Więcej funkcji tworzenia produktów będzie dostępnych online...",
        easy: "Łatwy",
        medium: "Średni",
        difficult: "Trudny",
        hour: "Godziny",
        minute: "protokół",
        cancel: "Anuluj",
        confirm: "Upewnij się",
        setTimeLimit24: "Ustaw limit czasu trwania na 24 godziny",
        peopleLimit20: "Ustaw górny limit 20 osób/porcji",
        selectDifficult: "Proszę wybrać poziom trudności gotowania",
        setPrepareTime: "Proszę wybrać liczbę osób biorących udział w posiłku",
        setTotalTime: "Proszę wybrać całkowity czas",
        setPeopleNum: "Proszę wybrać liczbę osób biorących udział w posiłku",
        ingredients: "Składniki",
        addIngredients: "DODAJ SKŁADNIKI",
        ingredientLimitTips: "Możesz dodać maksymalnie 20 rodzajów składników.",
        steps: "Kroki",
        addSteps: "DODAJ KROKI",
        stepLimitTips: "Możesz dodać maksymalnie 15 kroków.",
        uploadStepImg: "Prześlij obraz kroku",
        changeStepImg: "Zmień obraz kroku",
        inputStepDesc: "Proszę wprowadzić opis kroku",
        stepParams: "Parametry kroku",
        second: "towary drugiej jakości",
        timeLimitTips: "Ustaw maksymalny czas trwania na 99 minut",
        timesAlreadyEdit: 'Czas trwania został dostosowany do prawidłowej kombinacji dla Ciebie',
        inputTemp: "Temperatura",
        tempTips: "Zakres temperatury wynosi 35-180℃ i można ją regulować co 5 stopni",
        tempOff: "Temperatura wyłączona",
        tempOn: "Temperatura włączona",
        tempAlreadyEdit: "Temperaturę dostosowano do efektywnej kombinacji dla Ciebie",
        selectSpeed: "Proszę wybrać prędkość",
        positiveSpeed: "Prędkość dodatnia",
        reverseSpeed: "Prędkość wsteczna",
        tips: "Porady",
        inputTips: "Proszę wpisać napiwki (opcjonalnie)",
        label: "Tagi",
        addLabel: "DODAJ TAGI",
        viewMore: "Zobacz więcej",
        coverImgerror: "Dodaj obraz na okładkę przepisu",
        recipeNameError: "Wprowadź nazwę przepisu",
        ingredientError: "Dodaj co najmniej 1 składnik.",
        stepError: "Dodaj co najmniej 1 krok.",
        labelError: "Dodaj co najmniej 1 kategorię.",
        ingredientNameError: "Nazwa składnika nie może być pusta.",
        stepDescError: "Opis kroku nie może być pusty.",
        ingredientName: "woda",
        ingredientUnit: "100g",
        simpleHour: 'godz.',
        simpleMin: 'min'
    }
}