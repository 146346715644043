export default [
    {
        path: '/',
        component: resolve => require(['@/page/home/home'], resolve),
        title:'首页',
        hidden: true
    },
    {
        path: '/recipeSearch',
        component: resolve => require(['@/page/recipeSearch/recipeSearch'], resolve),
        title:'搜索列表',
        hidden: true,
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/recipeDetails',
        component: resolve => require(['@/page/recipeDetails/recipeDetails'], resolve),
        title:'食谱详情',
        hidden: true
    },
    {
        path: '/login',
        component: resolve => require(['@/page/login/login'], resolve),
        title:'登录',
        hidden: true
    },
    {
        path: '/register',
        component: resolve => require(['@/page/register/register'], resolve),
        title:'注册',
        hidden: true
    },
    {
        path: '/forgot',
        component: resolve => require(['@/page/forgot/forgot'], resolve),
        title:'忘记密码',  
        hidden: true
    },
    {
        path: '/setPassword',
        component: resolve => require(['@/page/setPassword/setPassword'], resolve),
        title:'设置密码',
        hidden: true
    },
    {
        path: '/setUsername',
        component: resolve => require(['@/page/setUsername/setUsername'], resolve),
        title:'设置用户名',
        hidden: true
    },
    {
        path: '/forgotPassword',
        component: resolve => require(['@/page/forgotPassword/forgotPassword'], resolve),
        title:'重置密码成功提示页', 
        hidden: true
    },
    {
        path: '/specialTopicList',
        component: resolve => require(['@/page/specialTopicList/specialTopicList'], resolve),
        title:'专题列表',
        hidden: true
    },
    {
        path: '/specialTopicInfo',
        component: resolve => require(['@/page/specialTopicInfo/specialTopicInfo'], resolve),
        title:'专题详情',
        hidden: true
    },
    {
        path: '/personalCenter',
        component: resolve => require(['@/page/personal/personalCenter'], resolve),
        title:'个人中心',
        hidden: true
    },
    {
        path: '/editprofile',
        component: resolve => require(['@/page/editprofile/editprofile'], resolve),
        title:'个人资料编辑页',
        hidden: true
    },
    {
        path: '/aboutus',
        component: resolve => require(['@/page/aboutus/aboutus'], resolve),
        title:'关于TOKIT',
        hidden: true
    },
    {
        path: '/privacyPolicy',
        component: resolve => require(['@/page/privacyPolicy/privacyPolicy'], resolve),
        title:'隐私政策',
        hidden: true
    },
    {
        path: '/termsService',
        component: resolve => require(['@/page/termsService/termsService'], resolve),
        title:'服务条款',
        hidden: true
    },
    {
        path: '/bindingprocess',
        component: resolve => require(['@/page/bindingprocess/bindingprocess'], resolve),
        title:'绑定设备',
        hidden: true
    },
    {
        path: '/createRecipe',
        component: resolve => require(['@/page/createRecipe/createRecipe'], resolve),
        title:'发布食谱',
        hidden: true
    },
    {
        path: '/userRecipeDetails',
        component: resolve => require(['@/page/userRecipeDetails/userRecipeDetails'], resolve),
        title:'创作详情',
        hidden: true
    },
]

