/**
 * 繁体中文
 */
export default{
    header:{
        Popular:'受歡迎的',
        collections:'最新的集合',
        store:'商店',
        about:'關於TOKIT',
        account:'我的帳戶',
        collection:'我的收藏',
        creation:'我的創作',
        device:'我的設備',
        signIn:'登入',
        loginOut:'登出'
    },
    fotter:{
        privacy:'隱私政策',
        terms:'服務條款',
        reserved:'版權所有：TOKIT 2020 tokit.com'
    },
    home:{
        featuredrecipes:'菜單',
        more:'瞭解更多',
        collections:'受歡迎的集合',
        delicacy:'特色美食',
        collection:'每日收藏',
        latestRecipes:'最新食谱',
        cookbook:'蒐索',
        search:'蒐索',
        link:'快速連結',
        getTheDelicacies: '獲取美食',
        gainSomeInspirations: "獲得一些靈感"
    },
    recipesearch:{
        noresult:'未找到所選蒐索的結果。',
        viewMore:'查看更多',
        collection:'集合已成功更新！',
        recipe:'食譜已從收藏中删除！',
        noMore: '暫無更多數據！',
        newest: '最新發布',
        shortest: '最受欢迎',
        recipes: '食譜',
        collections: '集合',
        tags: '標籤',  
        products: '分類',
        category: '類別',
        showResults: '查看結果',
        Filters: '篩選', 
        showAll: '展示全部',
        hideAll: '收起',
    },
    specialTopicList:{
        explore:'探索我們的最新系列',
        viewAll: '查看全部',
        Recipes:'道食譜'
    },
    login:{
        logs:'登入您的帳戶',
        email:'電子郵寄地址',
        psd:'密碼',
        by:'通過登入，您同意：',
        useragreement:'使用者協定',
        and:'和',
        login:'登入',
        or:'或',
        createaccount:'創建帳戶',
        forgotpassword:'忘記密碼',
        reserved:'©2021，純米科技（香港）有限公司。 版權所有。',
        agree:'我同意',
        privacy:'請閱讀並同意我們的政策。',
        enterpass:'輸入您的電子郵件以接收有關如何重置密碼的說明。',
        exist:'該帳戶不存在。',
        later:'出了問題，請稍後再試。',
        reset:'重置',
        return:'或返回到',
        denglu:'登入',
        resetpsd:'重置密碼',
        should:'您應該會在幾分鐘內收到一個連結。 請打開該連結以重置密碼。',
        backIn:'返回登入',
        setpad:'設置密碼',
        minimum:'最少8個字元，至少一個大寫字母、一個小寫字母和一個數位',
        confirm:'證實',
        confirmpsd:'確認密碼',
        passwordMismatch:'兩次密碼不匹配',
        incorrect :'密碼格式不正確',
        valids:'請提供有效密碼。',
        setName:'設定您的用戶名',
        tip:'昵称需唯一，使用中英文，且不包含特殊字符',
        wrong:'出了問題，請稍後再試。',
        usName:'用戶名已被使用。',
        save:'保存',
        validmail:'請提供有效的電子郵寄地址。',
        validpassword:'請提供有效密碼。',
        validname:'請提供有效的名稱',
        loginReminder:'登录提醒',
        format:'请匹配请求的格式'
    },
    recipeDetails:{
        difficulty:'難度',
        serves:'服務',
        preparationtime:'準備時間',
        totaltime:'總時間',
        ingredients:'成分',
        preparation:'準備',
        relatedrecipes:'相關食譜',
        min:'分',
        reterecipe:'評估這個食譜',
        evaluationTips:'從1星（非常差）到5星（非常好），你如何評估這個食譜？',
        angry:'生气',
        disappointed:'失望',
        average:'一般',
        satisfied:'满意',
        vsatisfied:'非常满意',
        clear:'取消',
        saveRecipes: '保存食谱',
        removeRecipes: '删除食谱',
        supportedProducts: '適用產品',
        tags: '標籤',
        tips:'小貼士',
        edit: '編輯',
        Duration:'時長',
        Temperature:'溫度',
        Speed:'轉速',
        corotation:'正轉',
        reversal:'反轉',
        minutes:'分鐘',
        easy:'簡單',
        Medium:'中等',
        Difficult:'較難',
        title:'食譜詳情',
        positive:'正轉',
        reverse:'反轉',
        needHour:'小時',
        needMin:'分鐘',
        
    },
    account:{
        hi:'你好,',
        profile:'我的個人資料',
        collection:'我的收藏',
        creation:'我的創作',
        device:'我的設備',
        editprofile:'編輯設定檔',
        firstname:'名字',
        lastname:'姓氏',
        gender:'性別',
        birthday:'生日日期',
        country:'國家/地區',
        email:'email',
        phonenum:'電話號碼',
        bundle:'捆綁您的TOKIT設備，享受更好的服務',
        addvice:'添加設備',
        bingdevice:'綁定設備',
        product:'產品',
        blank:'產品不能為空',
        deviceNo:'設備編號',
        modelBlank:'型號不能為空',
        submit:'提交',
        guide:'設備編號查看指南',
        content:'打開內容選單，按一下設定跳轉到頁面，然後按一下“關於”跳轉到該頁面，以查找型號未顯示的設備。 瞭解更多關於',
        fullTxt:'完整綁定過程',
        adddevice:'添加我的設備',
        choose:'請選擇您的產品。',
        modelNum:'型號',
        personal:'個人資訊',
        male:'男',
        female:'女',
        country:'國家或地區',
        year:'年份',
        month:'月份',
        day:'天',
        complete:'請在您的設備上完成綁定。',
        completed:'完成',
        noDevice:'我的設備上沒有快顯視窗',
        tryAlign:'再試一次',
        removeCollection:'删除集合',
        doreally:'是否確實要删除此集合？',
        firstRecipe:'保存您的第一個食譜！',
        theSave:'點擊保存',
        icon:'在配方上的圖標，以將其保存以備將來使用。',
        findRecipe:'查找食譜',
        successfully:'绑定成功！',
        removedevice:'設備已成功删除。',
        bindingFailed:'绑定失败',
        removeTOKIT: '删除TOKIT',
        removeTips: '你真的想停用你的TOKIT嗎？',
        cancel: '取消',
        remove: '删除',
		loadTip:'下載APP綁定設備'
    },
    aboutus:{
        seek:'我們尋求什麼',
        helping:'幫助烹飪更容易',
        journey:'烹飪現在可以是一次輕鬆愉快的旅程。',
        providing:'提供智慧烹飪解決方案',
        continuing:'繼續研究和開發最智慧的廚房體驗。',
        database:'基於雲的烹飪資料庫',
        building:'為用戶構建一個更加擴展和富有想像力的選單。',
        history:'歷史',
        previous:'上一個',
        next:'下一個',
        ourJourney:'我們的旅程始於五月',
        established:"成立於2013年5月，TOKIT Technology啟動了spark，並開始致力於物聯網和雲家電的開發和應用。",
        ourfirst:'我們推出的第一個產品',
        intend:'為了根據電子產品的科技要求重新定義廚房用具，我們從電鍋開始進行陞級和改革。',
        awards:'我們自2020年以來獲得的獎項',
        since:'自成立以來，我們獲得了許多組織的認可，從IF、Red Dot、G-Mark、IDEA等機构獲得了48個獎項。',
        launched:'TOKIT 原型發佈',
        developing:'我們在整個2020年開發了TOKIT原型，列為T0、T1和T2',
        Kickstarter:'$1142227在夏季Kickstartor上',
        reached:'隨著我們的TOKIT Omni Cook在全球正式推出，kickerstarter上有1566名支持者，銷售額達到1142227美元',
        website:'website And Cooknjoy即將到來的九月',
        distribution:'我們於2021 9月推出了全球官方網站和Cooknjoy平臺，為TOKIT全球分銷做準備。',
        Soon:'joyami很快就要來了',
        cooker:'我們將在2021年底推出我們的TOKIT子品牌joyami。下麵是joyami為我們的新電鍋提供的預覽。',
        define:'用溫度定義烹飪態度',
        creating:'創造豐富生活的可靠科技',
        staffed:'由來自全球科技巨頭蘋果、IBM、飛利浦和許多其他公司的300名精英組成； D隊占65%。 到目前為止，我們擁有800多項專利，並配備了互聯網遺傳學和先進製造技術'
    },
    creation:{
        edit:'修改食譜',
        delete:'刪除食譜',
        delTitle:'刪除食譜',
        delDesc:'是否確定要刪除這道食譜？',
        btnCancle:'取消',
        btnDel:'刪除',
        delSuccess:'食譜已刪除！',
        delFailed:'刪除失敗，請稍後再試。',
        addRecipe:'開始加入你的食譜',
        addDesc:'創作和編輯食譜如此簡單，快來試試吧！',
        publishRecipe:'上傳食譜',
        reviewing:'審核中',
        reviewFailed:'審核不通過',
    },
    userRecipe: {
        createdBy: "作者",
        done: "完成",
        uploadCoverImg: "上傳封面圖",
        changeCoverImg: "更換封面圖",
        browser: "瀏覽",
        uploadTips: "圖片格式支援jpg、jpeg、png、bmp、webp；2MB以內；建議尺寸",
        uploadLimitTip: "超出圖片大小限制2MB，請重新選擇",
        confirm1: "證實",
        reselect: "重選",
        uploadFail: "封面圖上傳失敗，請稍後重試。",
        recipeName: "食譜名稱",
        inputRecipeName: '請填寫食譜名稱',
        recipeNameTips: '名稱暫不支援特殊字符',
        description: "食譜描述",
        inputDesc: '請輸入描述（選填）',
        moreInfo: "更多資訊",
        supportProduct: "適用產品",
        difficulty: "難度",
        prepareTime: "準備時間",
        totalTime: "總時間",
        portion: "服務",
        productTips: "目前僅支援該產品的食譜創作，更多產品創作功能陸續上線...",
        easy: "簡單",
        medium: "中等",
        difficult: "較難",
        hour: "小時",
        minute: "分鐘",
        cancel: "取消",
        confirm: "確定",
        setTimeLimit24: "設定時長上限24小時",
        peopleLimit20: "設定人/份上限20",
        selectDifficult: "請選擇烹飪難度",
        setPrepareTime: "請選擇準備時間",
        setTotalTime: "請選擇總時間",
        setPeopleNum: "請選擇用餐人數",
        ingredients: "食材",
        addIngredients: "添加食材",
        ingredientLimitTips: "食材最多加20種哦",
        steps: "步驟",
        addSteps: "新增步驟",
        stepLimitTips: "步驟最多添加15個哦",
        uploadStepImg: "上傳步驟圖",
        changeStepImg: "更換步驟圖",
        inputStepDesc: "請輸入步驟描述",
        stepParams: "步驟參數",
        second: "秒",
        timeLimitTips: "設定長度上限99分鐘",
        timesAlreadyEdit: '時長已為您調整至有效組合',
        inputTemp: "請填寫溫度",
        tempTips: "溫度範圍為35-180℃，僅支持依5度調整",
        tempOff: "溫度 關閉",
        tempOn: "溫度 加熱",
        tempAlreadyEdit: "溫度已為您調整至有效組合",
        selectSpeed: "請選擇轉速",
        positiveSpeed: "正轉",
        reverseSpeed: "反轉",
        tips: "小貼士",
        inputTips: "請輸入小貼士（選填）",
        label: "標籤",
        addLabel: "添加標籤",
        viewMore: "查看更多",
        coverImgerror: "請加入食譜封面圖",
        recipeNameError: "請填寫食譜名稱",
        ingredientError: "請至少添加1種食材",
        stepError: "請至少添加1個步驟",
        labelError: "請至少添加1個分類",
        ingredientNameError: "食材名稱不能為空",
        stepDescError: "步驟描述不能為空",
        ingredientName: "水",
        ingredientUnit: "100g",
        simpleHour: '小時',
        simpleMin: '分鐘'
    }
}