<template>
  <div class="lang-drop">
    <a id="langdrop" class="lang-drop-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span>{{langText}}</span><img width="20" height="20" src="../assets/img/more2.png" alt="">
    </a>

    <ul class="dropdown-menu lang-drop-menu">
      <li v-for="(lang, index) in langList" :key="index" @click="changeLanguage(lang.abbr)" 
      :class="['lang-drop-item', $i18n.locale === lang.abbr ? 'checked': '']">{{ lang.title }}</li>
    </ul>
  </div>
</template>
 
<script>
export default {
  data() {
      return{
        langText:'繁體中文',
        langList: [
          { title: '简体中文', abbr: 'zh', lang: 'zh_CN' },
          { title: '繁體中文', abbr: 'tw', lang: 'zh_TW' },
          { title: 'English', abbr: 'en', lang: 'en_US' }, // 英语
          { title: '日本語', abbr: 'ja', lang: 'ja_JP' }, // 日语
          { title: 'Français', abbr: 'fr', lang: 'fr_FR' }, // 法语
          { title: 'Deutsch', abbr: 'de', lang: 'de_DE' }, // 德语
          { title: 'Italiano', abbr: 'it', lang: 'it_IT' }, // 意大利语
          { title: 'Español', abbr: 'es', lang: 'es_ES' }, // 西班牙语
          { title: 'Russian', abbr: 'ru', lang: 'ru_RU' }, // 俄罗斯语
          { title: 'Poland', abbr: 'pl', lang: 'pl_PL' }, // 波兰语
          { title: 'Bahasa Melayu', abbr: 'ms', lang: 'ms_MS' } // 马来语
        ]
      }
  },
  created() {
    this.setLang(localStorage.lang)
  },
  methods: {
    changeLanguage(lang) { // TODO app传进来的语言
      console.log(lang,'langlanglang')
      this.setLang(lang)
      this.$i18n.locale = lang // 设置给本地的i18n插件
      localStorage.lang=lang

      location.reload();
    },
    setLang(abbr){
      switch (abbr) {
        case 'zh':
          this.langText = '简体中文'
          localStorage.langT='zh_CN'
          break;
        case 'tw':
          this.langText = '繁體中文'
          localStorage.langT='zh_TW'
          break;
        case 'en':
          this.langText = 'English'
          localStorage.langT='en_US'
          break;
        case 'ja':
          this.langText = '日本語'
          localStorage.langT='ja_JP'
          break;
        case 'fr':
          this.langText = 'Français'
          localStorage.langT='fr_FR'
          break;
        case 'de':
          this.langText = 'Deutsch'
          localStorage.langT='de_DE'
          break;
        case 'it':
          this.langText = 'Italiano'
          localStorage.langT='it_IT'
          break;
        case 'es':
          this.langText = 'Español'
          localStorage.langT='es_ES'
          break;
        case 'ru':
          this.langText = 'Russian'
          localStorage.langT='ru_RU'
          break;
       case 'pl':
          this.langText = 'Poland'
          localStorage.langT='pl_PL'
          break;
        case 'ms':
          this.langText = 'Bahasa Melayu'
          localStorage.langT='ms_MS'
          break;
       
        default:
          this.langText = '繁體中文'
          localStorage.langT='zh_TW'
          break;
      }
    }
  }
}
</script>
<style scoped lang="scss">
 .lang-drop {
  position: relative;
  .lang-drop-btn {
    color: #606266;
    font-size: 14px;
    text-decoration: none;
    img {
      margin-left: 5px;
    }
  }

  .lang-drop-menu {
    position: absolute;
    left: -12px;
    top: 34px;
    min-width: 82px;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    border-radius: 4px;
    .lang-drop-item {
      line-height: 27px;
      padding: 0 15px;
      font-size: 13px;
      color: #999;
      cursor: pointer;
      white-space: nowrap;
      background: #ffffff;
      &:hover {
        color: #606266;
      }
    }
    .checked {
      color: #ff590d!important;
      // text-align: center;
      background-color: #fff!important;
    }
  }
 }
</style>