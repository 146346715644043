<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
    export default {
        name: 'APP',
        created() {
            let href = window.location.href
            if (href.indexOf('?source=') > -1 && href.indexOf('TOKITAPP') > -1) {
                const params = this.getQueryParams(href)
                localStorage.setItem('source', params.source)

                let language = params.language
                if (language === 'zh-Hant') {
                    language = 'tw'
                }else if(language === 'zh-Hans'){
                    language = 'zh'
                }else if(language === 'ms'){
                    language = 'ms'
                }
                console.log("-----------",language);
                this.setLang(language)
                this.$i18n.locale = language
			}
		},
        beforeDestroy() {
			localStorage.removeItem('source')
		},
        methods:{
            getQueryParams(url) {
                const queryParams = {};
               
                const queryString = url.split('?')[1];
                if (queryString) {
                    const pairs = queryString.split('&');
                   
                    pairs.forEach(pair => {
                    const [key, value] = pair.split('=');
                    queryParams[key] = decodeURIComponent(value); // 解码 URI 组件
                    });
                }
                return queryParams;
            },
            setLang(abbr){
                // 这里对应海外app里面传过来的值  
                switch (abbr) {
                    case 'zh-Hans':
                        this.langText = '简体中文'
                        localStorage.langT='zh_CN'
                        break;
                    case 'ms':
                        this.langText = 'Bahasa Melayu'
                        localStorage.langT='ms_MS'
                        break;
                    case 'zh-Hant':
                        this.langText = '繁體中文'
                        localStorage.langT='zh_TW'
                        break;
                    case 'en':
                        this.langText = 'English'
                        localStorage.langT='en_US'
                        break;
                    case 'ja':
                        this.langText = '日本語'
                        localStorage.langT='ja_JP'
                        break;
                    case 'fr':
                        this.langText = 'Français'
                        localStorage.langT='fr_FR'
                        break;
                    case 'de':
                        this.langText = 'Deutsch'
                        localStorage.langT='de_DE'
                        break;
                    case 'it':
                        this.langText = 'Italiano'
                        localStorage.langT='it_IT'
                        break;
                    case 'es':
                        this.langText = 'Español'
                        localStorage.langT='es_ES'
                        break;
                    case 'ru':
                        this.langText = 'Russian'
                        localStorage.langT='ru_RU'
                        break;
                    case 'pl':
                        this.langText = 'Poland'
                        localStorage.langT='pl_PL'
                        break;
                
                    default:
                        this.langText = '繁體中文'
                        localStorage.langT='zh_TW'
                        break;
                }
            }
        }
    }
</script>

<style lang="scss">
img, video{
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-user-drag: none;
}
#app {
    img, span, div, p, svg, g, rect, path, button{
        caret-color: rgba(0,0,0, 0)!important;
    }
    input {
        caret-color: rgba(0,0,0, 1)!important;
    }
    
}

</style>
